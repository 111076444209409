import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createEntityAdapter,
} from '@reduxjs/toolkit'
import { addNewComment, deleteComment, fetchComments, selectCommentById } from './comments'
import { useClientPost } from '../../../../app/useClient'

const highlightsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.time > a.time,
})

const initialState = highlightsAdapter.getInitialState({
  status: 'idle',
  error: null,
})

const highlightsSlice = createSlice({
  name: 'highlights',
  initialState,
  reducers: {
    highlightUpdated(state, action) {
      const { id, position, content } = action.payload
      const existingHighlight = state.entities[id]
      if (existingHighlight) {
        existingHighlight.position = {...existingHighlight.position, ...position}
        existingHighlight.content = content
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchComments.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchComments.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched highlights to the array
        const highlights = [];
        action.payload.forEach(function(comment) {
          const highlight = JSON.parse(comment["highlight"]);
          highlight["id"] = comment["id"];
          highlights.push(highlight);
        });
        highlightsAdapter.upsertMany(state, highlights)
      })
      .addCase(fetchComments.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(addNewComment.fulfilled, (state, action) => {
        let highlight = {
          ...action.payload["highlight"],
          id: action.payload["id"]
      };
          highlightsAdapter.addOne(state, highlight);
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        highlightsAdapter.removeOne(state, action.payload.comment.id)
      })
  },
})

export const { highlightAdded, highlightUpdated } = highlightsSlice.actions

export default highlightsSlice.reducer

export const {
  selectAll: selectAllHighlights,
  selectById: selectHighlightById,
  selectIds: selectHighlightIds,
} = highlightsAdapter.getSelectors((state) => state.highlights)

export const selectHighlightsByUser = createSelector(
  [selectAllHighlights, (state, userId) => userId],
  (highlights, userId) => highlights.filter((highlight) => highlight.user === userId)
)

export const selectAnnotatedHighlightsOld = createSelector(
  [selectAllHighlights],
  (highlights) => highlights.filter((highlight) => (highlight.content != null && highlight.position != null))
)

export const postUpdatedHighlight = createAsyncThunk(
  'comments/addNewComment',
  async (initialComment) => {
    const paths = window.location.pathname.split("/");
    const upload_id = paths[1];
    const highlight = initialComment.highlight
    let newComment = {
      ...initialComment, upload_id: upload_id, file_id: null, author_private_id: null
    }
    const response = await useClientPost(
      `transfers/annotations/add`, {...newComment, highlight: JSON.stringify(initialComment.highlight)}
    )
    newComment["id"] = response.data.toString();
    newComment["time"] = Date.now() / 1000;
    return newComment
  }
)

export const selectAnnotatedHighlights = state => {
  const items = selectAllHighlights(state);
  
  // apply your filter the way you need it
  return Object.values(items).filter(item => {
    if(item.content != null && item.position != null) {
      if(state.threads.currentFileId == null) {
        return false;
      }
      else {
        const comment = selectCommentById(state, item.id);
        if(comment.file_id == state.threads.currentFileId) {
          return true;
        }
        return false;
      }
    }
    return false
  })
}

