import { Button, ButtonGroup, Content, Dialog, DialogContainer, Divider, Flex, Form, Header, Heading, Link, Text, TextField } from "@adobe/react-spectrum";
import { Transfer_STATE } from "./Transfer";
import LockClosed from "@spectrum-icons/workflow/LockClosed";
import { useState } from "react";

export default function PasswordDialog({transfer, setEnteredPassword, timesFetchedData}) {

    const [entered, setEntered] = useState();

    function submit() {
        setEnteredPassword(entered);
    }

return(
<DialogContainer>
        {(transfer != null && transfer.Transfer_STATE == Transfer_STATE.NOT_AUTHENTICATED) &&
          <Dialog size="S">
          <Heading>
            <Flex alignItems="center" gap="size-100">
              <LockClosed size="S" />
              <Text>
                Password required
              </Text>
            </Flex>
          </Heading>
          <Header>
            <Link>
              <a href="https://help.ysendit.com/what-is-ysendit" target="_blank">What is this?</a>
            </Link>
          </Header>
          <Divider />
          <Content>
            <Form onSubmit={(event) => {event.preventDefault(); submit();}}>
              <TextField validationState={(transfer.Transfer_STATE == Transfer_STATE.NOT_AUTHENTICATED && (timesFetchedData > 1  || entered==""  )) ? 'invalid' : 'valid'} errorMessage={entered === ''
        ? 'Empty input not allowed.'
        : 'Entered password is wrong. Try again'} description="You probably received the password from the sender of this transfer. If not, kindly ask him for it" value={entered} onChange={setEntered} type="password" label="Enter password" autoFocus />
            </Form>
          </Content>
          <ButtonGroup>
            <Button variant="accent" onPress={() => submit()}>Enter</Button>
          </ButtonGroup>
        </Dialog>
        }
      </DialogContainer>
);
}

