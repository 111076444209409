import { Item, ListView, View } from '@adobe/react-spectrum';

import { useState, useEffect } from 'react';
import { SUPPORTED_PREVIEW_TYPES } from '../DownloadPage/FileView';
import Ad from '../Miscellaneous/Ad';
import { FileItem } from './FileItem';


export function FileList({selection, filePreviewHandler, fileSelectionHandler, files, transfer, openFileKey}) {
  const isQuiet = true; // show or hide row breakers in file list
  const [selectedKeys, setSelectedKeys] = useState(new Set());
  const [openedFileKey, setOpenedFileKey] = useState(new Set());
  const [oldSelection, setOldSelection] = useState(new Set());

  const [adKeys, setAdKeys] = useState([]);
  const [listItems, setListItems] = useState([]);

  useEffect(() => {
    if(!selection) {
      setOldSelection(selectedKeys);
      setSelectedKeys(openedFileKey);
    }
    else {
      // user has selected files before. Restore them
      if(oldSelection.size > 0) {
        handleSelection(oldSelection);
      }

      // user hasnt selected any files yet. Pre select the current viewed
      else {
        handleSelection(openedFileKey);
      }
      
    }

// empty dependency array means this effect will only run once (like componentDidMount in classes)
}, [selection]);

useEffect(() => {
  if(files.length > 0) {
    files.every(function(file, key) {
      if(file.preview != null || SUPPORTED_PREVIEW_TYPES.includes(file.type)) {
        let set = new Set();
        set.add(key.toString())
        setSelectedKeys(set);
        setOpenedFileKey(set);
        filePreviewHandler(file, false);
        return false;
      }
      return true;
  });

  let rows = [];
        let key = 0;
        let insertedAd = false;
        let tempAdKeys = [];
        while (key < files.length) {
          if(key % 2 == 0 && !insertedAd && 1 == 0) {
            insertedAd = true;
            tempAdKeys.push("adsbygoogle_" + key);
             rows.push(
            <Item key={"adsbygoogle_" + key} textValue={"adsbygoogle"}>
            <div style={{width:"700px"}}>
<Ad slot="6479295826" key={"ad_" + key} googleAdId="ca-pub-4451680474049677" format="fluid" layoutKey="-h2+d+5c-9-3e" className="fileListAd"  />
</div>
          </Item>
          );
          }
          else {
            insertedAd = false;
            rows.push(
              <Item key={files[key].keyInList} textValue={files[key].name}>
            <FileItem file={files[key]} filePreviewHandler={filePreviewHandler} setSelectedKeys={setSelectedKeys} setOpenedFileKey={setOpenedFileKey} itemKey={files[key].keyInList} selection={selection} transferId={(transfer != null) ? transfer.upload_id : ""} totalFiles={(files != null) ? files.length : 0} />
          </Item>
            );
            key++;
          }
        }
        setAdKeys(tempAdKeys);
        setListItems(rows);

  }
}, [files])

useEffect(() => {
  if(openFileKey != null) {
  openFile(openFileKey);
  }
}, [openFileKey])


    return(
    
      <View padding={isQuiet ? "5px" : "0px"}>  
        <ListView
        isQuiet={isQuiet}
  selectionMode={(selection == true) ? "multiple" : "single"}
  selectionStyle={(selection == true) ? "checkbox" : "highlight"}
  selectedKeys={selectedKeys}
  disabledKeys={adKeys}
  maxHeight="calc(100vh - 400px)"
  aria-label="downloadbale files"
  onSelectionChange={handleSelection}
>
{(() => {
        return listItems;
      })()}


</ListView>

</View>

    );

    function handleSelection(keys) {
      if(!selection) {
        const [fileToPreview] = keys;
        if(files[fileToPreview].preview != null || SUPPORTED_PREVIEW_TYPES.includes(files[fileToPreview].type)) {
          setSelectedKeys(keys);
          setOpenedFileKey(keys);
        if(fileToPreview != null) {
          filePreviewHandler(files[fileToPreview], true);
        }
        }
      }
      else {
        setSelectedKeys(keys);
        const selectedFiles = [];
        keys.forEach(fileId => selectedFiles.push(files[fileId]));
        fileSelectionHandler(selectedFiles);
      }
    }

    function openFile(key) {
      let set = new Set();
          set.add(key.toString())
          setSelectedKeys(set);
          setOpenedFileKey(set);
        if(files[key] != null) {
          filePreviewHandler(files[key], true);
        }
    }
}

