import { Button, ButtonGroup, Text } from "@adobe/react-spectrum";

import './KeepOpenToast.css';

import toast, { Toaster, useToasterStore } from 'react-hot-toast';

import { useState, useEffect } from "react";

export default function KeepOpenToast({notify}) {

    const { toasts } = useToasterStore();
    const TOAST_LIMIT = 1;

      useEffect(() => {
    // create a new toast every time download button gets pressed
    if(notify > 0) {
        toast((t) => (
          <span style={{display: "inline-flex", alignItems: "center"}}>
           <Text><b>Keep open</b> until download is done</Text>
           <ButtonGroup>
  <Button onClick={() => toast.dismiss(t.id)} >Ok</Button>
  <Button variant="secondary" onPress={() => window.open('https://help.ysendit.com/downloading-files', '_blank')}>Help</Button>
</ButtonGroup>
        </span>
    ));
}
      }, [notify]);

      useEffect(() => {
            // assure that there are not more than 1 toast at a time
            toasts
              .filter((t) => t.visible) // Only consider visible toasts
              .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
              .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
    
    
             
          }, [toasts]);


return(
    <Toaster position="top-center" toastOptions={{
        className: 'downloadToast',
        duration: 999999,
        style: {
          borderRadius: '10px'
        },
      }} />
);
}











