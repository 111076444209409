export default class File {
    constructor(key, id, name, url, type, size = 0, keyInList, preview = null) {
    this.key = key;
    this.id = id;
    this.name = name;
      this.url = url;
      this.type = (type != null) ? type.toLowerCase() : null;
      this.size = parseInt(size);
      this.keyInList = keyInList;
      this.preview = preview;
    }
}