import { Button, Collapse, Text } from "@mantine/core";
import { useState } from "react"

import './Quote.css'

function Quote({selectionContent}) {
    const[showQuote, setShowQuote] = useState(false);

    return(
        <>
    {selectionContent != null &&
<>
<Button onClick={() => {setShowQuote(!showQuote)}} variant="default" size="xs" radius="lg" compact>
  {showQuote ? "Hide related quote" : "Show related quote"}
</Button>
    <Collapse in={showQuote}>
        {(selectionContent.hasOwnProperty("text")) &&
    <Text size="xs" style={{wordBreak:'break-word'}}>{selectionContent.text}</Text>
}
{(selectionContent.hasOwnProperty("image")) &&
<div
                  className="highlight__image"
                  style={{ marginTop: "0.5rem" }}
                >
                  <img src={selectionContent.image} alt={"Screenshot"} />
                </div>
}
  </Collapse>
</>
    }
    </>
    )
}

export default Quote