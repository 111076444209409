import React from 'react'

import { selectCommentById } from './model/comments'
import { useSelector } from 'react-redux'
import { HoverCard, Text } from '@mantine/core'
import CommentAuthorDetails from './CommentAuthorDetails'


function CommentAuthor({commentId}) {

  const comment = useSelector((state) => selectCommentById(state, commentId))
if(comment != null && (comment.name != null || comment.email != null || comment.isAuthor)) {
  return (
    <HoverCard shadow="md" withArrow openDelay={200} closeDelay={50}>
        <HoverCard.Target>
           
        <Text>
          {(comment.isAuthor) ? "You" : ((comment.name != null) ? comment.name : comment.email)}
          </Text>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <CommentAuthorDetails name={comment.name} email={comment.email} avatar={comment.avatar} userId={comment.author_user_id} you={comment.isAuthor}/>
        </HoverCard.Dropdown>
      </HoverCard>
    
  )
}
else {
  return (null);
}
  
}

export default CommentAuthor