import { ActionIcon, Anchor, Space, Text } from '@mantine/core'
import React from 'react'

import Quote from './Quote'
import { deleteComment, selectCommentById } from './model/comments'
import { selectHighlightById } from './model/highlights'
import { useDispatch, useSelector } from 'react-redux'
import { IconTrash } from '@tabler/icons-react'


function Comment({commentId}) {

  const comment = useSelector((state) => selectCommentById(state, commentId))
  const highlight = useSelector((state) => selectHighlightById(state, commentId))

  const dispatch = useDispatch()

  const deleteCom = async () => {
      try {
        dispatch(deleteComment(commentId))
      } catch (err) {
        console.error('Failed to delete the comment: ', err)
      }
  }

  const options = { year: '2-digit', month: 'numeric', day: 'numeric', weekday: "long", hour: '2-digit', minute:'2-digit' };

  if(comment == null || comment == undefined) {
return(null)
  }
  else {
    return (
      <>
      
      <Text size="xs" mt={4}>{(new Date(comment.time * 1000)).toLocaleTimeString(undefined, options)}</Text>
          <Text color="dimmed" style={{overflowWrap: "break-word"}} size="sm">{highlight.comment.text}</Text>
          <Space h={4} />
          <Quote selectionContent={highlight.content} />
          {(comment.isAuthor && comment.reply_to_id != null) &&
          <>
          <Space h={4} />
      <Anchor  size="xs" mt={4} onClick={(e) => {deleteCom()}}>
      Delete
      </Anchor>
      </>
  }
  
          </>
    )
  }
  
}

export default Comment