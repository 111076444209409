import { Button, ButtonGroup, Content, Dialog, DialogContainer, Divider, Flex, Header, Heading, Link, Text } from "@adobe/react-spectrum";
import Delete from "@spectrum-icons/workflow/Delete";
import { Transfer_STATE } from "./Transfer";

export default function DeleteDialog({transfer, isDeleteModalOpen, setIsDeleteModalOpen, setDelete}) {

    

return(
<DialogContainer>
        {(isDeleteModalOpen && transfer != null && transfer.Transfer_STATE == Transfer_STATE.OK) &&
          <Dialog size="M">
          <Heading>
            <Flex alignItems="center" gap="size-100">
              <Delete size="S" />
              <Text>
                Delete transfer {(transfer != null) ? transfer.upload_id : ""}
              </Text>
            </Flex>
          </Heading>
          <Header>
            <Link>
              <a href="https://help.ysendit.com" target="_blank">What is this?</a>
            </Link>
          </Header>
          <Divider />
          <Content>
            <Text>
            After deletion, your transfer is no longer accessible. All associated files are immediately deleted. Other transfer data like file names, recipients, downloads will be deleted after 14 days at the latest
            </Text>
          </Content>
          <ButtonGroup>
          <Button variant="secondary" onPress={() => setIsDeleteModalOpen(false)}>Cancel</Button>
            <Button variant="negative" onPress={() => setDelete(1)}>Delete</Button>
          </ButtonGroup>
        </Dialog>
        }
      </DialogContainer>
);
}
