import { Flex, ProgressCircle } from '@adobe/react-spectrum'
import { ActionIcon, Avatar, Button, Center, Collapse, HoverCard, Paper, Space, Text, Textarea, Timeline, createStyles } from '@mantine/core'
import React, { useContext, useEffect, useRef, useState } from 'react'

import './NewComment.css'
import { IconClick, IconX } from '@tabler/icons-react'
import NewComment from './NewComment'
import Thread from './Thread'
import { useDispatch, useSelector } from 'react-redux'

import comments, {
  selectAllComments,
  fetchComments,
  selectCommentIds,
  selectCommentById
} from './model/comments'
import { getFilteredThreadIds, getFilteredThreads, selectThreadIds} from './model/threads'
import { selectAnnotatedHighlights } from './model/highlights'
import { hideHighlights } from '../PDFViewer/PDFViewer'
import { userContext } from '../../Auth/userContext'



function Comments({setHighlightsForViewer, transfer, setOpenFileKey}) {

    const dispatch = useDispatch()
    const orderedThreadIds = useSelector(getFilteredThreadIds)
    const highlights = useSelector(selectAnnotatedHighlights)
  
    const commentStatus = useSelector((state) => state.comments.status)
    const error = useSelector((state) => state.comments.error)

    const user = useContext(userContext);

    const [commentOnFocus, setCommentOnFocus] = useState(0);

    useEffect(() => {
      var myElementToCheckIfClicksAreInsideOf = document.querySelector('#cokk');
      // Listen for click events on body
      function handleClickOutside(event) {
        if (!myElementToCheckIfClicksAreInsideOf.contains(event.target)) {
        hideHighlights();
        setCommentOnFocus(0);
        } 
      }

      document.body.addEventListener('click', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
      };
    }, [])
  
    useEffect(() => {
      if(transfer != null && user != null && transfer.authenticated) {
        if (commentStatus === 'idle') {
          dispatch(fetchComments({password: transfer.enteredPassword}))
        }
        else if(commentStatus === 'succeeded') {
          setHighlightsForViewer(highlights);
        }
      }
      
    }, [commentStatus, dispatch, highlights, transfer, user])

    // reload after user has logged in
    useEffect(() => {
      if(user != null && commentStatus !== 'idle') {
        dispatch(fetchComments({password: transfer.enteredPassword}))
      }
    }, [user])
  
    let content

  if (commentStatus === 'loading') {
    content = <Center maw={400} h={100} mx="auto"><ProgressCircle aria-label="Loading…" isIndeterminate /></Center>
  } else if (commentStatus === 'succeeded') {
    if(orderedThreadIds.length > 0) {
      content = orderedThreadIds.map((threadId) => (
        <Thread onFocus={threadId == commentOnFocus} setCommentOnFocus={setCommentOnFocus} key={"thread-"+threadId} threadId={threadId} setOpenFileKey={setOpenFileKey} />
      ))
    }
    else {
      content = (<Text color="dimmed">It is pretty empty here. No comments posted yet.</Text>)
    }
    
  } else if (commentStatus === 'failed') {
    content = <div>Error</div>
  }

  return (
    
<Flex id="cokk" direction="column" gap="size-100" UNSAFE_style={{overflowY: "scroll"}}>

{content}

</Flex>
  )
}

export default Comments