import React, { useContext, useEffect, useState } from "react";
import NewComment from "./NewComment";
import Comments from "./Comments";
import { useOAuth2 } from "@tasoskakour/react-use-oauth2";
import { AuthContext, AuthProvider } from "react-oauth2-code-pkce";
import AuthHandler from "../../Auth/AuthHandler";
import { Button, Chip, Flex, Group, MediaQuery, Notification, Space, Text, Title } from "@mantine/core";
import { useDispatch } from "react-redux";
import { setFilter } from "./model/threads";
import { fileContext } from "../fileContext";

import './CommentSection.css';
import { IconGhost2, IconGhostOff, IconUser, IconUserCircle } from "@tabler/icons-react";
import { userContext } from "../../Auth/userContext";



export function CommentSection({
  setHighlightsForViewer, setAreaSelection, selectionContent, selectionPosition, hideSelection, setOpenFileKey, transfer, enableSelection
}) {

  const [filterComments, setFilterComments] = useState(['global', 'otherFiles']);

  const dispatch = useDispatch()

  const file = React.useContext(fileContext).currentFile; 

  const user = React.useContext(userContext);

  useEffect(() => {
    dispatch(setFilter({global: filterComments.includes("global"), otherFiles: filterComments.includes("otherFiles"), currentFileId: (file == null) ? null : file.id}))
  }, [filterComments, file])


  return (
    <div class="box">
  <div class="row header">
  <Title order={3} mb={10}>Comments</Title>
      <Chip.Group multiple value={filterComments} onChange={setFilterComments}>
        <Group position="left" spacing="xs" >
          <Chip size="xs" color="accent" value="global">global comments</Chip>
          <Chip size="xs" color="accent" value="otherFiles">other file's comments</Chip>
        </Group>
      </Chip.Group>
       {(user != null && user.id == null) &&
       <>
       <Space h="xl"></Space>
       <Notification icon={<IconGhostOff size="1.2rem" />} withCloseButton={false} withBorder color="accent" radius="md" title="Help others recognize you">
       Use your name, avatar and contact address
        <Button variant="default" onClick={() => {const handler = new AuthHandler(); handler.authorize();}} fullWidth mt="md">
        Sign in or Sign up
</Button>
      </Notification>
      </>
}

<Space h="xl"></Space>
      <NewComment replyToId={null} setAreaSelection={setAreaSelection} selectionContent={selectionContent} selectionPosition={selectionPosition} hideSelection={hideSelection} enableSelection={enableSelection} />
      
  </div>
  <div class="row content">
  <Comments transfer={transfer} setOpenFileKey={setOpenFileKey} setHighlightsForViewer={setHighlightsForViewer} />
  </div>
</div>

  );
}