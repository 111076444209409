import { useToggle, upperFirst, useDisclosure } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import {
  TextInput,
  PasswordInput,
  Text,
  Paper,
  Group,
  Button,
  Checkbox,
  Anchor,
  Title,
  Center,
  Image,
  LoadingOverlay,
  Box,
  rem,
  Space,
  Select,
} from '@mantine/core';
import ConsentModal from '../ConsentModal/components/ConsentModal';
import {useContext, useEffect, useState} from 'react';
import { UserAvatarUploader } from './UserAvatarUploader';
import {AlertDialog, DialogContainer, Flex, ProgressCircle} from '@adobe/react-spectrum';
import {IconArrowLeft, IconEPassport, IconHeartHandshake, IconKey, IconPassword} from '@tabler/icons-react';

import './AuthPage.css'
import FadeInOut from '../Miscellaneous/FadeInOut';
import {userContext} from "../Auth/userContext";
import {ChangePassword} from "./ChangePassword";

export function ProfilePage(props) {
  const queryParameters = new URLSearchParams(window.location.search)
  const redirectUri = decodeURI(queryParameters.get("redirect_to"));
  const [isChangePassOpen, setIsChangePassOpen] = useState(false);
  const [loader, handleLoader] = useDisclosure(true);

    const [confirmDeletionOpen, setConfirmDeletionOpen] = useState(false);

    const [availableDomains, setAvailableDomains] = useState([]);

  const user = useContext(userContext);

  const signupForm = useForm({
    initialValues: {
      email: '',
      name: '',
      customAvatar: '',
      marketing: true,
      defaultDomain: null
    },

    validate: {
      name: (val) => (val.length > 0 ? null : 'Please share your name'),
        email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email')
    },
  });

  useEffect(() => {
      if(user != null && user.authenticated) {
          signupForm.setValues({
              email: user.email,
              name: user.name,
              customAvatar: user.avatar,
              marketing: user.newsletter,
              defaultDomain: user.defaultDownloadDomain
          })
          if(user.allDownloadDomains && user.allDownloadDomains.length >= 1) {
            const domains = [{value: null, label: "https://ysendit.com"}];
            for (const domain of user.allDownloadDomains) {
              domains.push({value: domain.id, label: domain.domain})
            }
            setAvailableDomains(domains)
          }
          handleLoader.close();
      }
      else if(user != null && !user.authenticated) {
          window.location.href = "/auth?redirect_to=" + encodeURI(window.location.href);
      }
  }, [user])

  const [logo, setLogo] = useState(null);

  function initSiteData(data) {
    setLogo(process.env.REACT_APP_YSNDIT_ENDPOINT + data.logo.substring(1));
    const title = document.getElementById("title");
    title.textContent = "Update profile | " + data.siteName;
    const description = document.getElementById("description");
    description.content = data.description;
    const favicon = document.getElementById("favicon");
    favicon.href = process.env.REACT_APP_YSNDIT_ENDPOINT + data.favicon.substring(1);
    const largerIcon = document.getElementById("larger_icon");
    largerIcon.href = process.env.REACT_APP_YSNDIT_ENDPOINT + data.logo.substring(1);
  }

  function goBack(success) {
    window.close()
      window.location.href = (redirectUri != null) ? redirectUri : "/"
  }

  function update() {

    signupForm.validate();
    if(signupForm.isValid()) {
      handleLoader.open();
        var formdata = new FormData();
        formdata.append("name", signupForm.values.name);
        formdata.append("email", signupForm.values.email);
        formdata.append("newsletter", signupForm.values.marketing);
        formdata.append("avatar", signupForm.values.customAvatar);
        formdata.append("defaultDomain", signupForm.values.defaultDomain);

        var myHeaders = new Headers();
        if (process.env.NODE_ENV == 'development') {
          myHeaders.append("CF-Access-Client-Id", process.env.REACT_APP_CF_ID);
          myHeaders.append("CF-Access-Client-Secret", process.env.REACT_APP_CF_SECRET);
        }

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: formdata,
          redirect: 'follow',
          credentials: 'include'
        };
        fetch(process.env.REACT_APP_YSNDIT_ENDPOINT + "user/updateProfile", requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data["changed"]) {
                  window.location.href = redirectUri;
                }
                else {
                  handleLoader.close();
                  signupForm.setFieldError('email', 'An user with this mail already exists. Please choose an other mail address');
                }

            });
    }

    }

    function deleteAccount() {
        var myHeaders = new Headers();
        if (process.env.NODE_ENV == 'development') {
            myHeaders.append("CF-Access-Client-Id", process.env.REACT_APP_CF_ID);
            myHeaders.append("CF-Access-Client-Secret", process.env.REACT_APP_CF_SECRET);
        }

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            credentials: 'include'
        };
        fetch(process.env.REACT_APP_YSNDIT_ENDPOINT + "user/deleteUser", requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data["deleted"]) {
                    window.location.href = "/auth?accountDeleted";
                }
                else {

                }

            });
    }

    

  return (
   <>
       <DialogContainer onDismiss={() => setIsChangePassOpen(false)}>
           {isChangePassOpen &&
               <ChangePassword />
           }
       </DialogContainer>

       <DialogContainer onDismiss={() => setConfirmDeletionOpen(false)}>
           {confirmDeletionOpen &&
               <AlertDialog
                   variant="destructive"
                   title="Delete your account"
                   primaryActionLabel="Delete"
                   onPrimaryAction={() => deleteAccount()}
                   cancelLabel="Cancel">
                   This will permanently delete your transfers in your account and you can't log in anymore. Continue?
               </AlertDialog>
           }
       </DialogContainer>
   <ConsentModal requestedPage="download" reactCallback={null} reactPreflight={initSiteData} />
   <div className="center">
   <FadeInOut show={logo != null}>
   <Image height={80} fit="contain" src={logo} />
   </FadeInOut>





      <Title
        align="center"
        sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
      >
        Edit your profile
      </Title>
      <Text color="dimmed" size="sm" align="center" mt={5}>
        Not your account?{' '}
        <Anchor href={process.env.REACT_APP_YSNDIT_ENDPOINT + "user/logout?redirect=" + encodeURI(window.location.href)} size="sm">
          Logout
        </Anchor>
      </Text>


      <Paper withBorder pos="relative" shadow="md" p={30} mt={30} radius="md">
      <LoadingOverlay visible={loader} overlayBlur={2} radius="md" transitionDuration={100} loader={<ProgressCircle aria-label="Loading…" isIndeterminate />} />



        <Flex direction="row" alignItems="center" justifyContent="center" gap="size-250">

            {(!loader) &&
        <UserAvatarUploader initialAvatar={signupForm.values.customAvatar} username={signupForm.values.name} setCustomAvatar={(value) => {signupForm.setFieldValue('customAvatar', value)}} width="150px" />
            }
        <TextInput label="Display name" {...signupForm.getInputProps('name')} placeholder="Jack" required />
        </Flex>

        <TextInput label="Email" {...signupForm.getInputProps('email')} placeholder="jack@mail.com" required mt="md" />
          <Button mt={20} variant="default" onClick={() => {setIsChangePassOpen(true)}} leftIcon={<IconKey/>}>
              Change password
          </Button>
{(availableDomains.length > 0) &&
          <Select mt={17}
      label="Default download domain"
      placeholder="Pick one"
      value={signupForm.values.defaultDomain} onChange={(event) => signupForm.setFieldValue('defaultDomain', event)}
      data={availableDomains}
    />
}

{(user && user.adFree) &&
<Button mt={20} variant="default" onClick={() => {window.open("https://billing.stripe.com/p/login/28odUo1wb60m38AcMM")}} leftIcon={<IconHeartHandshake/>}>
      Update your Plan
    </Button>
}

        <Group position="apart" mt="lg">
       <Checkbox {...signupForm.getInputProps('marketing', { type: 'checkbox' })} label="Keep me informed about product news. You can unsubscribe at any time." />
        </Group>
        <Button onClick={() => {update()}} fullWidth mt="xl">
          Save
        </Button>

          <Button color="red" variant="subtle" onClick={() => {setConfirmDeletionOpen(true)}} fullWidth mt="xl">
              Delete account
          </Button>







      </Paper>
      <Space h="xl" />
      <Anchor onClick={() => {goBack(false);}} target="_blank">
      <Center pb={25}>
        <IconArrowLeft size={rem(14)} />
        <Box ml={5}>Back to website</Box>
      </Center>
    </Anchor>
    </div>
    </>

  );
}