import React from 'react'

import { Anchor, Avatar, Button, Group, Stack, Text } from '@mantine/core'
import AuthHandler from '../../Auth/AuthHandler';

function CommentAuthorDetails({name, email, avatar, userId, you, newComment}) {

  return (
    <>
        {(avatar != null || email != null || name != null) &&
          <Group mb="md">
            {(avatar != null) &&
            <Avatar src={process.env.REACT_APP_YSNDIT_ENDPOINT + "assets/img/custom/avatars/" + avatar} radius="xl" />
            }
            <Stack spacing={5}>
                {(name != null) &&
              <Text color="dimmed" size="sm" weight={700} sx={{ lineHeight: 1 }}>
                {name}
              </Text>
                }
                {(email != null) &&
              <Anchor
                href={"mailto:"+email}
                color="dimmed"
                size="xs"
                sx={{ lineHeight: 1 }}
              >
                {email}
              </Anchor>
                }
            </Stack>
          </Group>
          }

{(!newComment) &&
<>
        {(name == null && email == null && !you) &&
          <Text color="dimmed" maw="200px" size="xs">
            This comment was written by an anonymous user
          </Text>
        }
        {(userId == null && email != null && !you) &&
          <Text color="dimmed" maw="200px" size="xs">
            This comment was written by a user who received this transfer by mail
          </Text>
        }
        {(userId != null && !you) &&
          <Text color="dimmed" maw="200px" size="xs">
            This comment was written by an authenticated user
          </Text>
        }
        {(you) &&
          <Text color="dimmed" maw="200px" size="xs">
            This comment was written by you
          </Text>
        }
</>
    }

{(newComment && userId == null) &&
<>
<Text w={250} size="sm">To help others recognize you, please set up a profile</Text>
<Button variant="default" onClick={() => {const handler = new AuthHandler(); handler.authorize();}} fullWidth mt="md">
        Sign in or Sign up
</Button>
</>
    }

{(newComment && userId != null) &&
<>
    <Button.Group orientation="vertical">
        <Button component="a" href={process.env.REACT_APP_YSNDIT_ENDPOINT + "profile?redirect_to=" + encodeURI(window.location.href)} variant="default" fullWidth mt="md">
            Update profile
        </Button>
        <Button variant="default" component="a" href={process.env.REACT_APP_YSNDIT_ENDPOINT + "user/logout?redirect=" + encodeURI(window.location.href)} fullWidth mt="md">
            Sign out
        </Button>
    </Button.Group>
</>
    }



          </>
  )
}

export default CommentAuthorDetails