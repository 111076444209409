import React, { memo, useEffect, useState } from "react";
import classNames from "classnames";
import SwipeableBottomSheet from '@sergeymyssak/swipeable-bottom-sheet';
import '@sergeymyssak/swipeable-bottom-sheet/lib/min.css';
import "./BottomSheet.css";

const BottomSheet = ({
  isOpen,
  disableSwipe = false,
  onChange,
  children,
  containerClassName,
  bodyClassName
}) => {
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      setOpen(isOpen)
    }, 10)

    return () => clearTimeout(timeout)

  }, [isOpen])

  const [open, setOpen] = useState(isOpen);
  
  return (
  <SwipeableBottomSheet
    isOpen={open}
    onChange={onChange}
    swipeableViewsProps={{ disabled: disableSwipe }}
    containerClassName={classNames("custom-bottom-sheet", containerClassName)}
    bodyClassName={classNames("custom-bottom-sheet__body", bodyClassName)}
  >
    {children}
  </SwipeableBottomSheet>
)};

export default memo(BottomSheet);