import { Button, ButtonGroup, Checkbox, Content, Dialog, Divider, Flex, Form, Header, Heading, Link, Text, TextField, useDialogContainer } from '@adobe/react-spectrum';
import Key from '@spectrum-icons/workflow/Key';
import { useState } from 'react';


export function ChangePassword({}) {

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [ passwordConfirmation, setPasswordConfirmation ] = useState("");

  const [invalid, setInvalid] = useState(false);
  const [oldPasswordInvalid, setOldPasswordInvalid] = useState(false);

  let dialog = useDialogContainer();

  function changePass() {

    if(oldPassword.length >= 8 && newPassword.length >= 8 && newPassword == passwordConfirmation) {
        // POST request using fetch inside useEffect React hook
    var formdata = new FormData();
    formdata.append("oldPassword", oldPassword);
    formdata.append("newPassword", newPassword);
    
    var myHeaders = new Headers();
    if (process.env.NODE_ENV == 'development') {
      myHeaders.append("CF-Access-Client-Id", process.env.REACT_APP_CF_ID);
      myHeaders.append("CF-Access-Client-Secret", process.env.REACT_APP_CF_SECRET);
    }
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
      credentials: 'include'
    };
    fetch(process.env.REACT_APP_YSNDIT_ENDPOINT + "user/changePassword", requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data["changed"]) {
                setInvalid(false);
                setOldPasswordInvalid(false);
                dialog.dismiss();
            }
            else {
                setOldPasswordInvalid(true);
                setInvalid(true);
            }
          
        });
    }
    else {
      setInvalid(true);
    }
  }


  return (
    <Dialog size="S">
      <Heading>
        <Flex alignItems="center" gap="size-100">
          <Key size="S" />
          <Text>
            Change your password
          </Text>
        </Flex>
      </Heading>
      <Divider />
      <ButtonGroup>
        <Button variant="secondary" onPress={dialog.dismiss}>Cancel</Button>
        <Button autoFocus type='submit' variant="accent" onPress={() => changePass()}>Change</Button>
      </ButtonGroup>
      <Content>
        <Form>
          <TextField type='password' value={oldPassword} onChange={setOldPassword} isRequired necessityIndicator="icon" validationState={(invalid && (oldPassword.length < 8 || oldPasswordInvalid)) ? 'invalid' : ''} errorMessage="This is not your current password" label="Current password" />
          <TextField type='password' value={newPassword} onChange={setNewPassword} isRequired necessityIndicator="icon" validationState={(invalid && newPassword.length < 8) ? 'invalid' : ''} errorMessage='Your password must be at least 8 characters' label="New password" />
          <TextField type='password' value={passwordConfirmation} onChange={setPasswordConfirmation} isRequired necessityIndicator="icon" validationState={(invalid && newPassword != passwordConfirmation) ? 'invalid' : ''} errorMessage='Your passwords do not match' label="Confirm password" />          
        </Form>

      </Content>
    </Dialog>

  );
}
