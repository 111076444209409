import { Button, Content, Dialog, DialogTrigger, Divider, Flex, Heading, LabeledValue, Text } from '@adobe/react-spectrum';
import prettyBytes from 'pretty-bytes';


export default function TransferDetails({transfer, children}) {
  
    return(
        <DialogTrigger mobileType="tray" type="popover" hideArrow>
{children}
<Dialog>
  <Heading>Details of transfer {transfer.upload_id}</Heading>
  <Divider />
  <Content>

    <Flex direction="column" gap="size-300">
    {transfer.message != null && (
    <Text>
    {transfer.message}
    </Text>
    )}



    <Flex direction="row" gap="size-200" wrap> 
    <LabeledValue
  label="Size"
  value={prettyBytes(transfer.size)}
  minWidth="70px"
/>
<LabeledValue
  label="# of files"
  value={transfer.fileCount}
/>
<LabeledValue
  label="Time downloadable"
  width="140px"
  value={{ start: new Date(transfer.dateAdded * 1000), end: new Date(transfer.expiration * 1000)}}
  formatOptions={{ year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }}
/>
<LabeledValue
  label="Sender"
  width="140px"
  value={(transfer.emailFrom == "") ? "unknown" : transfer.emailFrom}
/>

    </Flex>
    

    
<Button variant="primary" style="outline" onPress={() => window.open('https://nwebr.atlassian.net/servicedesk/customer/portal/5/group/11/create/45?customfield_10043=' + 'https://ysendit.com/' + transfer.upload_id, '_blank')}>Report transfer</Button>
    </Flex>
  </Content>
  </Dialog>
  </DialogTrigger>
    );
}

