import { configureStore } from '@reduxjs/toolkit'

import commentsReducer from '../Components/DownloadPage/Annotations/model/comments'
import highlightsReducer from '../Components/DownloadPage/Annotations/model/highlights'
import threadsReducer from '../Components/DownloadPage/Annotations/model/threads'

export default configureStore({
  reducer: {
    comments: commentsReducer,
    highlights: highlightsReducer,
    threads: threadsReducer,
  },
})
