import { Flex, Heading, ProgressCircle, View } from '@adobe/react-spectrum';
import DownloadButton from './DownloadButton';

export function LoadingRow() {

    return(
        <View padding="10px">
        <Flex direction="row" wrap gap="size-150" alignItems="center">
        
            <ProgressCircle isIndeterminate />
        
          <View alignSelf="center">
          <Heading margin="0">Loading Transfer ...</Heading>
          </View>
          <View alignSelf="center" minWidth="200px" justifySelf="end">
          <DownloadButton isDownloadable={false} />
          </View>
          
        </Flex>
        </View>
    );
   
}