
  export default class SiteData {
      constructor(siteBackground="", siteLogo="", siteTerms="", siteImprint="", sitePrivacy="", siteName="", description="", favicon="", googleAdId="", slot_MainRow="", alternateAdImage="", alternateAdLink="", help_url="", consent) {
        this.siteBackground = siteBackground;
        this.siteImprint = siteImprint;
        this.siteTerms = siteTerms;
        this.siteLogo = siteLogo;
        this.sitePrivacy = sitePrivacy;
        this.siteName = siteName;
        this.description = description;
        this.favicon = favicon;
        this.googleAdId = googleAdId;
        this.slot_MainRow = slot_MainRow;
        this.alternateAdImage = alternateAdImage;
        this.alternateAdLink = alternateAdLink;
        this.help_url = help_url;
        this.consent = consent;
    }
  }