import React from 'react'

import { selectCommentById } from './model/comments'
import { useSelector } from 'react-redux'
import { Avatar, HoverCard } from '@mantine/core'
import CommentAuthorDetails from './CommentAuthorDetails'


function CommentBullet({commentId}) {

  const comment = useSelector((state) => selectCommentById(state, commentId))

  if(comment != null) {
    return (
        <HoverCard shadow="md" withArrow openDelay={200} closeDelay={50}>
            <HoverCard.Target>
            <Avatar radius="xl" src={process.env.REACT_APP_YSNDIT_ENDPOINT + "assets/img/custom/avatars/" + comment.avatar} size="sm" color={comment.isAuthor ? "blue" : "gray"} />
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <CommentAuthorDetails name={comment.name} email={comment.email} avatar={comment.avatar} userId={comment.author_user_id} you={comment.isAuthor}/>
            </HoverCard.Dropdown>
          </HoverCard>
      )
  }
  else {
    return(null);
  }
  
}

export default CommentBullet