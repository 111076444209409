import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createEntityAdapter,
} from '@reduxjs/toolkit'
import { addNewComment, deleteComment, fetchComments, selectAllComments, selectCommentById } from './comments'

const threadsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.time > a.time,
})

const initialState = threadsAdapter.getInitialState({
  status: 'idle',
  global: true,
  otherFiles: true,
  currentFileId: null,
  error: null,
})

const threadsSlice = createSlice({
  name: 'threads',
  initialState,
  reducers: {
    threadUpdated(state, action) {
      const { id, newComment } = action.payload
      const existingThread = state.entities[id]
      if (existingThread) {
        existingThread.comments = [...existingThread.comments, newComment]
      }
    },
    setFilter(state, action) {
      state.status = 'succeeded'
      const { global, otherFiles, currentFileId } = action.payload
      state.global = global;
      state.otherFiles = otherFiles;
      state.currentFileId = currentFileId;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchComments.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchComments.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched highlights to the array
        const threads = [];
        action.payload.forEach(function(comment) {
          if(comment["reply_to_id"] == null || comment["reply_to_id"] == 0) {
            threads.push({ id: comment["id"], isAuthor: comment["isAuthor"], file_id: comment["file_id"], comments: [ comment["id"] ] });
          }
          else {
            threads.find(x => x.id === comment["reply_to_id"])["comments"].push(comment["id"])
          }
        });
        threadsAdapter.upsertMany(state, threads)
      })
      .addCase(fetchComments.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(addNewComment.fulfilled, (state, action) => {
        if(action.payload["reply_to_id"] == null || action.payload["reply_to_id"] == 0) {
          const thread = { id: action.payload["id"], isAuthor: action.payload["isAuthor"], file_id: action.payload["file_id"], comments: [ action.payload["id"] ] };
          threadsAdapter.addOne(state, thread);
        }
        else {
          state.entities[action.payload["reply_to_id"]].comments = [...state.entities[action.payload["reply_to_id"]].comments, action.payload["id"]]
        }
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        if(action.payload.comment["reply_to_id"] == 0 || action.payload.comment["reply_to_id"] == null) {
          threadsAdapter.removeOne(state, action.payload.comment.id)
        }
        else {
          state.entities[action.payload.comment["reply_to_id"]].comments = state.entities[action.payload.comment["reply_to_id"]].comments.filter(function(e) { return e !== action.payload.comment.id })
        }
      })
  },
})

export const { threadAdded, threadUpdated, setFilter } = threadsSlice.actions

export default threadsSlice.reducer

export const {
  selectAll: selectAllThreads,
  selectById: selectThreadById,
  selectIds: selectThreadIds
} = threadsAdapter.getSelectors((state) => state.threads)

export const selectThreadsByUser = createSelector(
  [selectAllThreads, (state, userId) => userId],
  (threads, userId) => threads.filter((thread) => thread.user === userId)
)

export const getFilteredThreadIds = state => {
  const items = selectThreadIds(state);
  
  if ((state.threads.global && state.threads.otherFiles) || state.threads.currentFileId == null) {
    return items;
  }
  
  // apply your filter the way you need it
  return Object.values(items).filter(item => {
    const comment = selectCommentById(state, item);
    if(comment.file_id == state.threads.currentFileId) {
      return true;
    }
    else {
      if(comment.file_id != null) {
        return state.threads.otherFiles;
      }
      else {
        return state.threads.global;
      }
    }
  })
}