import { AlertDialog, Button, ButtonGroup, Checkbox, Content, Dialog, DialogContainer, Divider, Flex, Form, Header, Heading, Link, Text, TextField, useDialogContainer } from '@adobe/react-spectrum';
import Key from '@spectrum-icons/workflow/Key';
import { useState } from 'react';


export function ResetPassword({}) {

  const [newPassword, setNewPassword] = useState("");
  const [ passwordConfirmation, setPasswordConfirmation ] = useState("");

  const [invalid, setInvalid] = useState(false);

  const [state, setState] = useState("");

  const urlParams = new URLSearchParams(window.location.search);


  let dialog = useDialogContainer();

  function resetPass() {

    if(newPassword.length >= 8 && newPassword == passwordConfirmation) {
        // POST request using fetch inside useEffect React hook
    var formdata = new FormData();
    formdata.append("newPassword", newPassword);
    formdata.append("reset_code", urlParams.get('reset_code'));
    formdata.append("email", urlParams.get('email'));
    
    var myHeaders = new Headers();
    if (process.env.NODE_ENV == 'development') {
      myHeaders.append("CF-Access-Client-Id", process.env.REACT_APP_CF_ID);
      myHeaders.append("CF-Access-Client-Secret", process.env.REACT_APP_CF_SECRET);
    }
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(process.env.REACT_APP_YSNDIT_ENDPOINT + "user/resetPassword", requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data["reset"]) {
                setState("success");
            }
            else {
                setState("error");
            }
          
        });
    }
    else {
      setInvalid(true);
    }
  }


  return (
    <Dialog size="S">
      <Heading>
        <Flex alignItems="center" gap="size-100">
          <Key size="S" />
          <Text>
            Reset your password
          </Text>
        </Flex>
      </Heading>
      <Divider />
      <ButtonGroup>
        <Button variant="secondary" onPress={dialog.dismiss}>Cancel</Button>
        <Button autoFocus type='submit' variant="accent" onPress={() => resetPass()}>Reset</Button>
      </ButtonGroup>
      <Content>
        <Form>
          <TextField type='password' value={newPassword} onChange={setNewPassword} isRequired necessityIndicator="icon" validationState={(invalid && newPassword.length < 8) ? 'invalid' : ''} errorMessage='Your password must be at least 8 characters' label="New password" />
          <TextField type='password' value={passwordConfirmation} onChange={setPasswordConfirmation} isRequired necessityIndicator="icon" validationState={(invalid && newPassword != passwordConfirmation) ? 'invalid' : ''} errorMessage='Your passwords do not match' label="Confirm password" />          
        </Form>
      </Content>

      <DialogContainer>
        {(state == "success") &&
      <AlertDialog
    variant="confirmation"
    title="Password has been reset"
    primaryActionLabel="Ok"
    onPrimaryAction={() => {setState(""); dialog.dismiss();}}
    >
    Your password has been successfully set to the password you specified. Kindly log in with this password now.
  </AlertDialog>
}
      </DialogContainer>
      <DialogContainer>
        {(state == "error") &&
      <AlertDialog
    variant="error"
    title="An error has occurred"
    primaryActionLabel="Ok"
    onPrimaryAction={() => {setState(""); dialog.dismiss();}}
    >
    Your password could not be reset. Maybe the link you used was expired. Please try to reset your password again.
  </AlertDialog>
}
      </DialogContainer>
    </Dialog>

  );
}
