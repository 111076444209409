import React, { PureComponent } from 'react';
import './Ad.css';

class Ad extends PureComponent {
  googleInit = null;

  componentDidMount() {
    
      const { timeout } = this.props;
    this.googleInit = setTimeout(() => {
      if (typeof window !== 'undefined')
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, timeout);
  }

  shouldComponentUpdate(nextProps) {
    return false;
  }

  componentWillUnmount() {
    if (this.googleInit) clearTimeout(this.googleInit);
  }

  render() {
    const { slot, googleAdId, style, format, layoutKey, className, key, children, alternateLink } = this.props;
    return (
      <div class="ad">
        <ins key={key}
          className={"adsbygoogle " + className}
          style={style || { display: 'block'}}
          data-ad-client={googleAdId}
          data-ad-layout-key={layoutKey}
          data-ad-slot={slot}
          data-ad-format={format || "auto"}
        >
        </ins>

        <a href={alternateLink} class="alternateAd">
        {children}
        </a>
        </div>
    );
  }
}
Ad.defaultProps = {
  layoutKey: '',
  className:'',
  timeout: 200,
};
export default Ad;