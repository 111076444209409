
import FadeInOut from "../Miscellaneous/FadeInOut";
import Plyr from 'plyr-react';
import "plyr-react/plyr.css";
import './FileView.css';
import { Button} from "@adobe/react-spectrum";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import ChevronRight from "@spectrum-icons/workflow/ChevronRight";
import ChevronLeft from "@spectrum-icons/workflow/ChevronLeft";
import PDFViewer from "./PDFViewer/PDFViewer";
import { useHotkeys } from "react-hotkeys-hook";



export const SUPPORTED_PREVIEW_TYPES = ["png", "jpeg", "jpg", "gif", "bmp", "pdf", "mp4", "webm", "ogg", "mp3", "wav"];

export const video_types = ["mp4", "ogg", "webm"];
export const audio_types = ["mp3", "wav", "ogg"];
export const photo_types = ["png", "jpeg", "jpg", "gif", "bmp"];

export const supportsPDFViewer = (file) => {
  return file.type == "pdf" || photo_types.includes(file.type) || file.preview != null;
}

export const supportsAnnotations = (file) => {
  return supportsPDFViewer(file);
}

export default function FileView({file, files, setFullscreen, setOpenFileKey, setSelectionPosition, setSelectionContent, areaSelection, hideSelection, highlights}) {
    

    const [localFullscreen, setLocalFullscreen] = useState(false);

    const [prevFilePreviewable, setPrevFilePreviewable] = useState(false);
    const [nextFilePreviewable, setNextFilePreviewable] = useState(false);

    const [generatedPDF, setGeneratedPDF] = useState(null);


    useHotkeys(['arrowLeft', 'ctrl#arrowLeft'], () => {
      if(prevFilePreviewable) {
        openPrevFile();
      }
    }, {combinationKey:"#"})

    useHotkeys(['arrowRight', 'ctrl#arrowRight'], () => {
      if(nextFilePreviewable) {
        openNextFile();
      }
    }, {combinationKey:"#"})

    const getMeta = (url, cb) => {
      const img = new Image();
      img.onload = () => cb(null, img);
      img.onerror = (err) => cb(err);
      img.src = url;
    };
    
    

    useEffect(() => {
      if(file != null) {
        setNextFilePreviewable(checkNextFilePreviewable());
        setPrevFilePreviewable(checkPrevFilePreviewable());
        setOpenFileKey(null);
      }
      /*
      if(photo.includes(file.type)) {
        let width = 0;
        let height = 0;
        // Use like:
    getMeta(file.url, (err, img) => {
      width = img.naturalWidth;
      height = img.naturalHeight;
      let doc = null;
      if(width > height) {
        doc = new jsPDF('l', 'mm', [width, height]);
      }
      else {
        doc = new jsPDF('p', 'mm', [height, width]);
      }

doc.addImage(img, file.type, 10, 10, width, height);
setGeneratedPDF(doc.output('bloburl'));
    });

    

      }
      */
    }, [file]);

    const plyrSource = useMemo(
      () => ({ type: file == null ? null : ((video_types.includes(file.type)) ? "video" : "audio"),
      sources: [{ src: file == null ? null : file.url, type: file == null ? null : ((video_types.includes(file.type)) ? "video/" + file.type : "audio/" + file.type)}] }),
      [file]
    );
    const plyrOptions = useMemo(
      () => ({
        hideControls: true,
                fullscreen: { enabled: true, fallback: true, iosNative: true, container: null },
                controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'pip', 'airplay', 'fullscreen'],
                keyboard: { focused: true, global: true },
                ads: { enabled: false, publisherId: '', tagUrl: 'https://pubads.g.doubleclick.net/gampad/ads?iu=/22430538404/ysndit-plyr&description_url=[placeholder]&tfcd=0&npa=0&sz=400x300&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&correlator=[placeholder]&vpmute=0&vpa=click&vpos=preroll' }
      }),
      []
    );

    return(
        <>

        

{(files.length > 1) && (
 <div style={{position:"absolute", top:"50%", transform: "translateY(-50%)", width:"100%", zIndex:5}}>
<Button position="absolute" left="15px" isDisabled={!prevFilePreviewable} onPress={() => openPrevFile()} variant="secondary" style="fill" aria-label="Previous file"><ChevronLeft /></Button>
<Button position="absolute" right="15px" isDisabled={!nextFilePreviewable} onPress={() => openNextFile()} variant="secondary" style="fill" aria-label="Next file"><ChevronRight /></Button>
</div>

)}


        
        {(file != null && (file.preview != null || (SUPPORTED_PREVIEW_TYPES.includes(file.type) && !video_types.includes(file.type) && !audio_types.includes(file.type)))) && (

<PDFViewer toggleFullscreen={toggleFullscreen} src={(file.preview == null) ? file.url : file.preview} type={file.type} image={photo_types.includes(file.type)} highlights={highlights} hideSelection={hideSelection} areaSelection={areaSelection} setSelectionContent={setSelectionContent} setSelectionPosition={setSelectionPosition}  />
               )}
      {(file != null) && (
<FadeInOut show={SUPPORTED_PREVIEW_TYPES.includes(file.type) && (video_types.includes(file.type) || audio_types.includes(file.type))} duration={200}>
<Plyr key="player" source={plyrSource} options={plyrOptions}/>
</FadeInOut>
)}
</>
    );

    function openNextFile() {
      let firstFound = false;
      for(let key = file.keyInList + 1; key < files.length; key++) {
        if(files[key].preview != null || SUPPORTED_PREVIEW_TYPES.includes(files[key].type)) {
          if(!firstFound) {
            setPrevFilePreviewable(true);
            setOpenFileKey(key);
            firstFound = true;
          }
          else {
            setNextFilePreviewable(true);
            break;
          }
        }
      }
    }

    function openPrevFile() {
      let firstFound = false;
      for(let key = file.keyInList - 1; key >= 0; key--) {
        if(files[key].preview != null || SUPPORTED_PREVIEW_TYPES.includes(files[key].type)) {
          if(!firstFound) {
            setNextFilePreviewable(true);
            setOpenFileKey(key);
            firstFound = true;
          }
          else {
            setPrevFilePreviewable(true);
            break;
          }
        }
      }
    }

    function checkNextFilePreviewable() {
      for(let key = file.keyInList + 1; key < files.length; key--) {
        if(files[key].preview != null || SUPPORTED_PREVIEW_TYPES.includes(files[key].type)) {
          return true;
        }
      }
    }

    function checkPrevFilePreviewable() {
      for(let key = file.keyInList - 1; key >= 0; key--) {
        if(files[key].preview != null || SUPPORTED_PREVIEW_TYPES.includes(files[key].type)) {
          return true;
        }
      }
    }

    function toggleFullscreen() {
        setFullscreen(!localFullscreen);
        setLocalFullscreen(!localFullscreen);
    }
}