import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createEntityAdapter,
} from '@reduxjs/toolkit'
import { useClientPost } from '../../../../app/useClient'

const commentsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.time > a.time,
})

const initialState = commentsAdapter.getInitialState({
  status: 'idle',
  error: null,
})

export const fetchComments = createAsyncThunk(
  'comments/fetchComments',
  async (input, { getState }) => {
    const allComments = selectAllComments(getState())
    const [latestComment] = allComments
    const latestTimestamp = latestComment ? latestComment.time : ''
    const paths = window.location.pathname.split("/");
    const upload_id = paths[1];
    const response = await useClientPost(
      `transfers/annotations/get`, { upload_id: upload_id, since: latestTimestamp, password: input.password }
    )
    return response.data
  }
)

export const addNewComment = createAsyncThunk(
  'comments/addNewComment',
  async (initialComment) => {
    const paths = window.location.pathname.split("/");
    const upload_id = paths[1];
    const highlight = initialComment.highlight
    let newComment = {
      ...initialComment, upload_id: upload_id
    }
    const response = await useClientPost(
      `transfers/annotations/add`, {...newComment, highlight: JSON.stringify(initialComment.highlight)}
    )
    newComment["id"] = response.data.toString();
    newComment["time"] = Date.now() / 1000;
    
    return newComment
  }
)

export const deleteComment = createAsyncThunk(
  'comments/deleteComment',
  async (data, {getState}) => {
    const response = await useClientPost(
      `transfers/annotations/delete`, {id: data}
    )
    const returnData = {comment: selectCommentById(getState(), data)};    
    return returnData;
  }
)

const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    commentUpdated(state, action) {
      const { id, title, content } = action.payload
      const existingComment = state.entities[id]
      if (existingComment) {
        existingComment.title = title
        existingComment.content = content
      }
    },

  },
  extraReducers(builder) {
    builder
      .addCase(fetchComments.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchComments.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        const comments = action.payload.map(({highlight, ...rest}) => {
          return rest;
        });
        commentsAdapter.upsertMany(state, comments)
      })
      .addCase(fetchComments.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(addNewComment.fulfilled, commentsAdapter.addOne)
      .addCase(deleteComment.fulfilled, (state, action) => {
        state.status = 'succeeded'
        commentsAdapter.removeOne(state, action.payload.comment.id)
      })
  },
})

export const { commentAdded, commentUpdated } = commentsSlice.actions

export default commentsSlice.reducer

export const {
  selectAll: selectAllComments,
  selectById: selectCommentById,
  selectIds: selectCommentIds,
} = commentsAdapter.getSelectors((state) => state.comments)

export const selectCommentsByUser = createSelector(
  [selectAllComments, (state, userId) => userId],
  (comments, userId) => comments.filter((comment) => comment.user === userId)
)
