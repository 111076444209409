import React from "react";
import Settings from "@spectrum-icons/workflow/Settings";
import { Button, ButtonGroup, Content, Dialog, DialogTrigger, Divider, Flex, Heading, Link, Text} from '@adobe/react-spectrum';


export default function ManageButton({transfer, privateId, setIsDeleteModalOpen}) {


    return (
       <>    



      <DialogTrigger type="fullscreen">
      <Button width="size-400" variant="secondary" aria-label="Settings"><Settings /></Button>
  {(close) => (
    <Dialog>
      <Heading>Manage transfer {transfer.upload_id}</Heading>
      <Divider />
      <Content>
        <Flex direction="column" gap="size-250">

        <Text>You have edit rights for this transfer. When sharing, make sure you remove the privateID "{privateId}" from the url <Link><a href={"https://ysendit.com/" + transfer.upload_id + "/" + privateId} target="_blank">https://ysendit.com/{transfer.upload_id}/{privateId}</a></Link>. Anyone who has the privateID can edit the transfer.</Text>
      <ButtonGroup orientation="vertical">
  <Button variant="negative" onPress={() => {setIsDeleteModalOpen(true)}}>Delete this transfer</Button>
</ButtonGroup>

        </Flex>
      
      </Content>
      <ButtonGroup>
        <Button variant="secondary" onPress={close} autoFocus>Close</Button>
      </ButtonGroup>
    </Dialog>
  )}
</DialogTrigger>
       </>
    );
   
}
