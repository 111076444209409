import React, { useState } from 'react'

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFilePoster from 'filepond-plugin-file-poster'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css'
import { View } from '@adobe/react-spectrum'

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFilePoster)

// Our app
export function UserAvatarUploader({initialAvatar, username, width, setCustomAvatar}) {
const [avatar, setAvatar] = useState(initialAvatar);
  const [files, setFiles] = useState([
    {
        // the server file reference
        source: username,

        // set type to local to indicate an already uploaded file
        options: {
            type: 'local',

            // optional stub file information
            file: {
                name: username+'.png',
                size: 3001025,
                type: 'image/png',
            },

            // pass poster property
            metadata: {
                poster: (avatar != null) ? process.env.REACT_APP_YSNDIT_ENDPOINT + "assets/img/custom/avatars/" + avatar : null,
            },
        },
    },
]);

  return (
    <View width={width}>
      <FilePond
        files={(avatar != null) ? files : null}
        onupdatefiles={setFiles}
        allowMultiple={false}
        maxFiles={1}
        instantUpload={true}
        server={{
            url: process.env.REACT_APP_YSNDIT_ENDPOINT + "user/uploadProfileImage",
            process: {
                method: 'POST',
                headers: {
                    "CF-Access-Client-Id": process.env.REACT_APP_CF_ID,
                    "CF-Access-Client-Secret": process.env.REACT_APP_CF_SECRET
                },
                onload: (response) => {setCustomAvatar(response); setAvatar(response);},
            }
            
        }}
        onremovefile={() => {setCustomAvatar(null); setAvatar(null);}}
        name="filepond" /* sets the file input name, it's filepond by default */
        labelIdle='Drag a Profile Image or <span class="filepond--label-action">Browse</span>'
        imagePreviewHeight="170"
        credits={false}
    imageCropAspectRatio='1:1'
    imageResizeTargetWidth="200"
    imageResizeTargetHeight="200"
    stylePanelLayout='compact circle'
    styleLoadIndicatorPosition='center bottom'
    styleProgressIndicatorPosition='right bottom'
    styleButtonRemoveItemPosition='left bottom'
    styleButtonProcessItemPosition='right bottom'
     />
    </View>
  )
}