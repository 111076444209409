export async function useClient(endpoint, { body, ...customConfig } = {}) {

    let headers = { 'Content-Type': 'application/json', 'Authorization' : window.localStorage.getItem("token"), 'Browser-Id' : window.localStorage.getItem('browser_id'), 'Private-Id' : window.location.pathname.split("/")[2] }
    if (process.env.NODE_ENV == 'development') {
        headers = { 'Content-Type': 'application/json', 'Authorization' : window.localStorage.getItem("token"), 'Browser-Id' : window.localStorage.getItem('browser_id'), 'Private-Id' : window.location.pathname.split("/")[2], 'CF-Access-Client-Id':  process.env.REACT_APP_CF_ID, 'CF-Access-Client-Secret': process.env.REACT_APP_CF_SECRET}
    }
  
    const config = {
      method: body ? 'POST' : 'GET',
      ...customConfig,
      headers: {
        ...headers,
        ...customConfig.headers,
      },
      credentials: 'include'
    }
  
    if (body) {
      config.body = JSON.stringify(body)
    }
  
    let data
    try {
      const response = await window.fetch(process.env.REACT_APP_YSNDIT_ENDPOINT + endpoint, config)
      data = await response.json()
      if (response.ok) {
        // Return a result object similar to Axios
        return {
          status: response.status,
          data,
          headers: response.headers,
          url: response.url,
        }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(err.message ? err.message : data)
    }
  }
  
  export const useClientGet = function (endpoint, customConfig = {}) {
    return useClient(endpoint, { ...customConfig, method: 'GET' })
  }
  
  export const useClientPost = function (endpoint, body, customConfig = {}) {
    return useClient(endpoint, { ...customConfig, body })
  }
  