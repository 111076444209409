import React, { Component } from "react";

import {
  PdfLoader,
  PdfHighlighter,
  Tip,
  Highlight,
  Popup,
  AreaHighlight,
} from "@darian-lp/react-pdf-highlighter";


import { testHighlights as _testHighlights } from "./test-highlights";
import { Spinner } from "./Spinner";
import { Sidebar } from "./Sidebar";

import "./style/PDFViewer.css";
import { ActionButton, ActionGroup, ActionMenu, Flex, Icon, Item, SearchField, Section, Text, ToggleButton } from "@adobe/react-spectrum";
import ChevronLeft from "@spectrum-icons/workflow/ChevronLeft";
import ChevronRight from "@spectrum-icons/workflow/ChevronRight";
import { IconArrowAutofitHeight, IconArrowAutofitWidth, IconMessage, IconRotate, IconRotate2, IconShape, IconWindowMaximize, IconZoomIn, IconZoomOut } from "@tabler/icons-react";
import PinOff from "@spectrum-icons/workflow/PinOff";
import { Notification, Portal } from "@mantine/core";
import { connect, useSelector } from "react-redux";
import { highlightUpdated, selectAnnotatedHighlights } from "../Annotations/model/highlights";
import jsPDF from "jspdf";


const commentingOn = true;

const testHighlights = _testHighlights;

const parseIdFromHash = () =>
  document.location.hash.slice("#highlight-".length);

const resetHash = () => {
  document.location.hash = "";
};

const getMeta = (url, cb) => {
  const img = new Image();
  img.onload = () => cb(null, img);
  img.onerror = (err) => cb(err);
  img.src = url;
};

const HighlightPopup = ({
  comment,
}) =>
  comment.text ? (
    <div className="Highlight__popup">
      {comment.emoji} {comment.text}
    </div>
  ) : null;

class PDFViewer extends Component {
  state = {
    searchValue: "",
    pagesRotation: 0,
    url: null,
    highlights: this.props.highlights,
    scaleValue: "page-width",
    areaSelection: this.props.areaSelection,
    currentMatch: 0,
    totalMatchCount: 0,
    selectionContent: null,
    selectionPosition: null,
    hideSelection: null,
    };


  resetHighlights = () => {
    this.setState({
      highlights: [],
    });
  };


  toggleDocument = () => {
    const newUrl =
    this.props.src;

    if(this.props.image) {
      this.setState({
        url: null,
      });
      let width = 0;
        let height = 0;
        // Use like:
    getMeta(this.props.src, (err, img) => {
      width = img.naturalWidth;
      height = img.naturalHeight;
      const maxDim = 4000;
      if(width > maxDim || height > maxDim) {
        if(width >= height) {
          const ratio = height / width;
          width = maxDim;
          height = ratio * width;
        }
        else {
          const ratio = width / height;
          height = maxDim;
          width = ratio * height;
        }

      }
      let doc = null;
      if(width > height) {
        doc = new jsPDF('l', 'mm', [width, height]);
      }
      else {
        doc = new jsPDF('p', 'mm', [height, width]);
      }

      doc.setFillColor(29, 29, 29);
      doc.rect(0, 0, width, height, "F");

doc.addImage(img, this.props.type, 0, 0, width, height);
this.setState({
  url: doc.output('bloburl'),
});

    });
    }
    else {
      this.setState({
        url: newUrl,
      });
    }
    

          


  };

  setAreaSelection = (enabled) => {
    this.setState({ areaSelection: enabled })
  }

  componentDidUpdate(prevProps) {
      if (prevProps.src !== this.props.src) {
        this.toggleDocument();
      }
      if (prevProps.areaSelection !== this.props.areaSelection) {
        this.setAreaSelection(this.props.areaSelection);
      }
      if (prevProps.hideSelection !== this.props.hideSelection && this.props.hideSelection > 0) {
        this.state.hideSelection();
      }
      if (prevProps.highlights !== this.props.highlights) {
        this.setState({ highlights: this.props.highlights });
      }
    }

  scrollViewerTo = (highlight) => {};

  findNext = () => {};

  findPrev = () => {};

  scrollToHighlightFromHash = () => {
    const highlight = this.getHighlightById(parseIdFromHash());

    if (highlight) {
      this.scrollViewerTo(highlight);
    }
    else {
      hideHighlights()
    }
  };

  componentDidMount() {
    this.toggleDocument();
    window.addEventListener(
      "hashchange",
      this.scrollToHighlightFromHash,
      false
    );
  }

  getHighlightById(id) {
    const { highlights } = this.state;

    return highlights.find((highlight) => highlight.id === id);
  }

  updateHighlight(highlightId, position, content) {

    this.props.highlightUpdated({id: highlightId, position: position, content: content})

    this.setState({
      highlights: this.state.highlights.map((h) => {
        const {
          id,
          position: originalPosition,
          content: originalContent,
          ...rest
        } = h;
        return id === highlightId
          ? {
              id,
              position: { ...originalPosition, ...position },
              content: { ...originalContent, ...content },
              ...rest,
            }
          : h;
      }),
    });
  }

  setPagesRotation = (pagesRotation) => {
    this.setState({ pagesRotation });
  };

  zoomIn() {
    if(typeof this.state.scaleValue != "number") {
      this.setState({ scaleValue: 1 })
    }
    else {
      this.setState({ scaleValue: this.state.scaleValue + 0.25 })
    }
  }

  setAction(action) {
    if(action == "rotate") {
      this.setPagesRotation(this.state.pagesRotation + 90);
    }
    else if(action == "full-height") {
      this.setState({ scaleValue: "page-height" })
    }
    else if(action == "full-width") {
      this.setState({ scaleValue: "page-width" })
    }
    else if(action == "zoom-in") {
      this.zoomIn();
    }
    else if(action == "zoom-out") {
      if(typeof this.state.scaleValue != "number") {
        this.setState({ scaleValue: 1 })
      }
      else if(this.state.scaleValue > 0.25) {
        this.setState({ scaleValue: this.state.scaleValue - 0.25 })
      }
    }
    else if(action == "prevSearch") {
      this.findPrev();
    }
    else if(action == "nextSearch") {
      this.findNext();
    }
    else if(action == "fullscreen") {
      this.props.toggleFullscreen()
    }
  }

  render() {
    const {
      url,
      highlights,
      scaleValue,
      searchValue,
      currentMatch,
      totalMatchCount,
      pagesRotation,
    } = this.state;

    

    return (
      <>
      {(url != null) &&
      <div className="PDFViewer" style={{ display: "flex", height: "100vh" }}>
        
        {(1==0) &&
        <Sidebar
          setHighlightsForViewer={(highlights) => {
            this.setState({
              highlights: highlights,
            });
          }}
          setAreaSelection={this.setAreaSelection}
          updatedHighlight={this.state.updatedHighlight}
          selectionPosition={this.state.selectionPosition}
          selectionContent={this.state.selectionContent}
          hideSelection={this.state.hideSelection}
        />
  }
        <div
          style={{
            height: "100vh",
            width: "100%",
            position: "relative",
          }}
        >
          {(this.state.areaSelection) &&
          <Notification className="areaSelectionNote" icon={<IconShape width="1.2rem" />} radius="md" title="Highlight to comment on">
        Draw a rectangle with your cursor around the part you want to highlight
      </Notification>
  }
  <Portal target={document.getElementById("headerRow")}>
  <Flex gap="size-150" zIndex={5} direction="row">
          <Flex direction="column" gap="size-50" alignItems="end">
          <SearchField
        onChange={(searchValue) => this.setState({ searchValue })}
        onClear={() => this.setState("")}
        width="100%"
        onSubmit={() => this.findNext()}
        />
        {(totalMatchCount > 0) &&

        
        <ActionGroup onAction={(action) => this.setAction(action)} density="compact">
  <Item key="prevSearch" aria-label="previous search result"><ChevronLeft /></Item>
  <Item key="nothing" aria-label="current search result">{currentMatch} / {totalMatchCount}</Item>
  <Item key="nextSearch" aria-label="next search result"><ChevronRight /></Item>
</ActionGroup>
}
{(totalMatchCount == 0 && this.state.searchValue != "") &&
<ActionButton>No matches found</ActionButton>
}
          </Flex>

<ActionMenu onAction={(action) => this.setAction(action)}>
  <Section title="Layout"></Section>
  <Item key="zoom-in">
    <Icon size="S" UNSAFE_style={{fill: "none"}}>
    <IconZoomIn />
    </Icon>
    <Text>Zoom in</Text>
  </Item>
  <Item key="zoom-out">
    <Text>Zoom out</Text>
    <Icon size="S" UNSAFE_style={{fill: "none"}}>
    <IconZoomOut />
    </Icon>
  </Item>
  <Item key="full-width">
    <Text>Full width</Text>
    <Icon size="S" UNSAFE_style={{fill: "none"}}>
    <IconArrowAutofitWidth />
    </Icon>
  </Item>
  <Item key="full-height">
    <Text>Full height</Text>
    <Icon size="S" UNSAFE_style={{fill: "none"}}>
      <IconArrowAutofitHeight />
    </Icon>
  </Item>
  <Item key="rotate">
  <Text>Rotate</Text>
  <Icon size="L" UNSAFE_style={{fill: "none"}}>
      <IconRotate2 />
      </Icon>
   
  </Item>
  <Item key="fullscreen">
    <Text>Toggle fullscreen</Text>
    <Icon size="S" UNSAFE_style={{fill: "none"}}>
      <IconWindowMaximize />
    </Icon>
  </Item>
</ActionMenu>


</Flex>
  </Portal>
          

          <PdfLoader url={url} beforeLoad={<Spinner />}>
          
            {(pdfDocument) => (
              <PdfHighlighter
                pagesRotation={pagesRotation}
                searchValue={searchValue}
                onSearch={(currentMatch, totalMatchCount) => {
                  this.setState({ currentMatch, totalMatchCount });
                }}
                findRefs={(findPrev, findNext) => {
                  this.findPrev = findPrev;
                  this.findNext = findNext;
                }}
                pdfDocument={pdfDocument}
                enableAreaSelection={(event) => this.state.areaSelection}
                onScrollChange={resetHash}
                pdfScaleValue={scaleValue}
                scrollRef={(scrollTo) => {
                  this.scrollViewerTo = scrollTo;

                  this.scrollToHighlightFromHash();
                }}
                onSelectionFinished={(
                  position,
                  content,
                  hideTipAndSelection,
                  transformSelection
                ) => {
                  transformSelection();
                  this.setState({selectionContent: content, selectionPosition: position, hideSelection:hideTipAndSelection})
                  this.props.setSelectionPosition(position);
                  this.props.setSelectionContent(content);
                }}
                highlightTransform={(
                  highlight,
                  index,
                  setTip,
                  hideTip,
                  viewportToScaled,
                  screenshot,
                  isScrolledTo
                ) => {

              

                  const isTextHighlight = !Boolean(
                    highlight.content && highlight.content.image
                  );

                  const component = isTextHighlight ? (
                    <Highlight
                      isScrolledTo={isScrolledTo}
                      position={highlight.position}
                      comment={highlight.comment}
                    />
                  ) : (
                    <AreaHighlight
                      isScrolledTo={isScrolledTo}
                      highlight={highlight}
                      onChange={(boundingRect) => {
                        

                        //uncomment to enable re-positioning of annotations
                        /* 
                        
                        this.updateHighlight(
                          highlight.id,
                          { boundingRect: viewportToScaled(boundingRect) },
                          { image: screenshot(boundingRect) }
                        );
                        */

                      }}
                    />
                  );

                  return (
                   
                    <Popup
                      popupContent={<HighlightPopup {...highlight} />}
                      onMouseOver={(popupContent) =>
                        setTip(highlight, (highlight) => popupContent)
                      }
                      onMouseOut={hideTip}
                      key={index}
                      children={component}
                    />
                   
                  );
                }}
                highlights={highlights}
              />
            )}
          </PdfLoader>
        </div>
      </div>
      }
      {(url == null) &&
      <Spinner />
      }
      </>
    );
  }
}

export function hideHighlights() {
  
    const areaHighlights = document.querySelectorAll('.AreaHighlight--scrolledTo');

  areaHighlights.forEach(highlight => {
highlight.classList.remove('AreaHighlight--scrolledTo');
});

const textHighlights = document.querySelectorAll('.Highlight--scrolledTo');

  textHighlights.forEach(highlight => {
highlight.classList.remove('Highlight--scrolledTo');
});

// notwendig, dass bei erneuter auswahl der highlight nochmal kommt

const elements = document.getElementsByClassName("PdfHighlighter")
  elements[0].scrollBy({
    top: -1,
  });
window.history.replaceState(null, null, ' ');
}

export const highlightInViewer = (highlightId) => {
  
  document.location.hash = `highlight-${highlightId}`;
};

const mapDispatchToProps = (dispatch) => {
  return {
    highlightUpdated: (props) => dispatch(highlightUpdated(props))
  }
};

export default connect(null, mapDispatchToProps)(PDFViewer);
