import { Flex, Icon, ToggleButton, View, useProvider } from '@adobe/react-spectrum';
import { useEffect, useState } from 'react';
import { DownloadPill } from '../DownloadPill/DownloadPill';
import AppFooter from '../DownloadPill/AppFooter';
import FadeInOut from '../Miscellaneous/FadeInOut';
import FileView, { SUPPORTED_PREVIEW_TYPES, audio_types, photo_types, supportsAnnotations, video_types } from './FileView';

import './DownloadPage.css';
import { Transfer_STATE } from '../DownloadPill/Transfer';
import { Group, Transition } from '@mantine/core';
import { Provider } from 'react-redux';
import store from '../../app/store';


import { transferContext } from '../DownloadPill/transferContext';

import { fileContext } from './fileContext';
import { CommentSection } from './Annotations/CommentSection';
import BottomSheet from '../Miscellaneous/Mobile/BottomSheet';
import { useMediaQuery } from '@mantine/hooks';
import { IconMessage, IconMessageOff, IconWindowMaximize, IconWindowMinimize } from '@tabler/icons-react';
import { useHotkeys } from 'react-hotkeys-hook';


function DownloadPage() {

    const [file, setFile] = useState();
    const [files, setFiles] = useState();
    const [transfer, setTransfer] = useState();
    const [fullscreen, setFullscreen] = useState(false);

    //key of file to open in preview
    const [openFileKey, setOpenFileKey] = useState();

    const [selectionPosition, setSelectionPosition] = useState();
    const [selectionContent, setSelectionContent] = useState();
    const [areaSelection, setAreaSelection] = useState();
    const [hideSelection, setHideSelection] = useState(0);
    const [highlightsForViewer, setHighlightsForViewer] = useState();

    const isMobile = useMediaQuery('(max-width: 830px)');

    const [commentOpen, setCommentOpen] = useState(false);

    const scaleX = {
      in: { opacity: 1, transform: 'translateX(10)' },
      out: { opacity: 0, transform: 'translateX(-10)' },
      common: { transformOrigin: 'left' },
      transitionProperty: 'transform, opacity',
    };

    

    const commentSection = (
      <CommentSection setHighlightsForViewer={setHighlightsForViewer}
          setAreaSelection={setAreaSelection}
          selectionPosition={selectionPosition}
          selectionContent={selectionContent}
          hideSelection={() => {setHideSelection(hideSelection + 1)}}
          setOpenFileKey={setOpenFileKey}
          enableSelection={!isMobile && file != null && supportsAnnotations(file)}
          transfer={transfer} />
    );

    useHotkeys('esc', () => {
      if(fullscreen) {
        setFullscreen(!fullscreen);
      }
    })

    useEffect(() => {
      if(!isMobile && transfer != null && transfer.allowComments) {
        setCommentOpen(true);
      }
    }, [transfer])

    
    

  return (
    <>
    <Provider store={store}>
      <transferContext.Provider value={transfer}>
      <fileContext.Provider value={{currentFile: file, files: files}}>





      {(isMobile) &&
      <BottomSheet  isOpen={commentOpen} onChange={setCommentOpen}>
        <div className='comments' style={{padding: "15px", height: "100vh"}}>
      {commentSection}
      </div>
    </BottomSheet>
}



      <div style={{ display: 'flex', height: '100vh', width: '100vw', maxHeight: '100vh'}}>
      
      <main style={{ width: "100%", position: "relative" }}>
        <div>

        <Group position="apart" id="headerRow" style={{padding: "12px", position:"absolute", zIndex:5, width: (file != null && (video_types.includes(file.type) || audio_types.includes(file.type))) ? "100px" : "100%", flexWrap: "nowrap"}}>
<Flex gap="size-100" direction="row">
<ToggleButton isEmphasized isSelected={fullscreen} onPress={() => {setFullscreen(!fullscreen)}}>
{(!fullscreen) &&
<Icon size="S" UNSAFE_style={{fill: "none"}}>
<IconWindowMinimize />
  </Icon>
}
{(fullscreen) &&
  <Icon size="S" UNSAFE_style={{fill: "none"}}>
 
  <IconWindowMaximize />
  </Icon>
}
</ToggleButton>

{(transfer != null && transfer.allowComments) &&
<ToggleButton aria-label="Comments" isEmphasized isSelected={commentOpen} onPress={() => setCommentOpen(!commentOpen)}>
{(!commentOpen) &&
  <Icon size="S" UNSAFE_style={{fill: "none"}}>
  <IconMessage />
  </Icon>
}
{(commentOpen) &&
  <Icon size="S" UNSAFE_style={{fill: "none"}}>
  <IconMessageOff />
  </Icon>
}
</ToggleButton>
}


</Flex>
</Group>


          <FadeInOut show={file == null || (!SUPPORTED_PREVIEW_TYPES.includes(file.type) && file.preview == null) || transfer == null} duration={200}>
    <div style={{"textAlign": "center", "width":"100%", "height":"100vh"}}>
    {(transfer != null) && (
    <iframe src={((transfer.backgroundUrl == "" || transfer.backgroundUrl == null) ? transfer.siteData.siteBackground : transfer.backgroundUrl) + "?adFree=" + transfer.adFree + "&consent=" + JSON.stringify(transfer.siteData.consent)} style={{backgroundColor:"transparent", border:"unset"}} width="100%" height="100%"></iframe>
    )}
    </div>
    </FadeInOut>
    <FadeInOut show={transfer != null && transfer.Transfer_STATE == Transfer_STATE.OK} duration={200}>
    <FileView file={file} files={files} setFullscreen={setFullscreen} setOpenFileKey={setOpenFileKey} highlights={highlightsForViewer} hideSelection={hideSelection} areaSelection={areaSelection} setSelectionContent={setSelectionContent} setSelectionPosition={setSelectionPosition} />
    </FadeInOut>
    <DownloadPill setFile={setFile} setUpperFiles={setFiles} setUpperTransfer={setTransfer} fullscreen={fullscreen} openFileKey={openFileKey} />
    <FadeInOut show={!fullscreen} duration={200}>
    <View UNSAFE_className='appFooter' borderColor="gray-300" borderWidth="thin" borderRadius="small" position="fixed" bottom="size-200" right="size-200" padding="5px" isHidden={{ base: true, M: false }}>
    {(transfer != null) && (
    <AppFooter imprint={transfer.siteData.siteImprint} terms={transfer.siteData.siteTerms} privacy={transfer.siteData.sitePrivacy} help={transfer.siteData.help_url} />
    )}
    </View>
    </FadeInOut>
        </div>
      </main>
      {(!isMobile) &&
      <Transition mounted={commentOpen} transition={"fade"} keepMounted duration={250} timingFunction="ease">
      {(styles) => 
      <div className='comments' width='350px' style={{ ...styles, minWidth: "350px", maxWidth: "350px", borderLeft: "1px solid rgba(99, 99, 99, 0.45)" }}>
        <div style={{padding: "15px", height: "calc(100vh - 30px)"}}>
        {commentSection}
        </div>
      </div>
      }
      </Transition>
      }
    </div>
    
        
    
    </fileContext.Provider>
    </transferContext.Provider>
    </Provider>
    </>
  );
}

export default DownloadPage;
