import React from "react";
import { Divider, Flex, Footer, Link} from '@adobe/react-spectrum';


export default function AppFooter({imprint, privacy, terms, help}) {


    return (
        <Footer UNSAFE_style={{fontSize: '10px'}}><Flex gap="size-125" justifyContent="end"> <Link ><a href={help} target="_blank">What is this?</a></Link> <Divider size='S' orientation="vertical" /> <Link isQuiet variant='secondary'><a href={terms} target="_blank">Terms</a></Link> <Link isQuiet variant='secondary'><a href={privacy} target="_blank">Privacy</a></Link> <Link isQuiet variant='secondary'><a href={imprint} target="_blank">Imprint</a></Link> <Link justifySelf="end" isQuiet variant='secondary'><a href="https://ysendit.statuspage.io/" target="_blank">System status</a></Link></Flex></Footer>
    );
   
}