import { ActionIcon, Avatar, Button, Chip, HoverCard, Paper, Text, Textarea, createStyles } from '@mantine/core'
import React, { useEffect, useState } from 'react'

import './NewComment.css'
import { IconClick, IconX } from '@tabler/icons-react'
import Quote from './Quote'
import { useDispatch } from 'react-redux'
import { addNewComment } from './model/comments'
import { userContext } from '../../Auth/userContext'
import CommentAuthorDetails from './CommentAuthorDetails'
import { transferContext } from '../../DownloadPill/transferContext'
import { fileContext } from '../fileContext'
import { Flex } from '@adobe/react-spectrum'

function NewComment({setAreaSelection, selectionContent, selectionPosition, hideSelection, replyToId, enableSelection}) {

  const [comment, setComment] = useState("");
  const [onFocus, setOnFocus] = useState(false);


  const [areaSelection, enableAreaSelection] = useState(false);
  const [isSelection, setIsSelection] = useState(false);

  const [selectionContentIntern, setSelectionContentIntern] = useState(null);

  const [pinned, setPinned] = useState(true);

  const user = React.useContext(userContext); 
  const file = React.useContext(fileContext).currentFile; 
  const transfer = React.useContext(transferContext); 

  function toggleSelection() {
    const newValue = !(isSelection);
    if(!newValue) {
        hideSelection();
        setSelectionContentIntern(null);
    }
    else {
      setPinned(true);
    }
    if(newValue != areaSelection) {
        enableAreaSelection(newValue);
        setAreaSelection(newValue);
    }
    setIsSelection(newValue);
    
  }

  function close() {
    if(isSelection) {
      toggleSelection()
      }
    setComment(""); 
  }

  const textAreaStyle = createStyles((theme) => ({
    input: {
        padding: '2px',
    },

    root: {
        width: "100%"
    },

  
    
    
  }));

  const [addRequestStatus, setAddRequestStatus] = useState('idle')

  const dispatch = useDispatch()

  const canSave = addRequestStatus === 'idle'

  const post = async () => {
    if (canSave) {
      const highlight = {
        content: selectionContentIntern,
        position: (selectionContentIntern != null) ? selectionPosition : null,
        comment: {
          text: comment,
          emoji: null
        }
      };
      try {
        setAddRequestStatus('pending')
        await dispatch(addNewComment({ highlight: highlight, reply_to_id: replyToId, file_id: (pinned) ? file.id : null, email: (user.email == "" || user == null || user.email == null) ? transfer.receiverMail : user.email, avatar: user.avatar, name: user.name, isAuthor: true})).unwrap()
      } catch (err) {
        console.error('Failed to save the comment: ', err)
      } finally {
        close()
        setAddRequestStatus('idle')
      }
    }
  }

  useEffect(() => {
    setSelectionContentIntern(selectionContent);
    if(selectionContent != null && selectionContent.hasOwnProperty("text") && !isSelection) {
        setIsSelection(true);
    }
  }, [selectionContent]);
  const { classes } = textAreaStyle();
  const inner = (
    <>
    <Flex direction="row" gap="size-100">
    <HoverCard shadow="md" withArrow openDelay={200} closeDelay={50}>
        <HoverCard.Target>
    <Avatar src={(user == null) ? "" : (process.env.REACT_APP_YSNDIT_ENDPOINT + "assets/img/custom/avatars/" + user.avatar)} radius="xl" color='blue' size="sm" />
    </HoverCard.Target>
        <HoverCard.Dropdown>
          {(user != null) &&
          <CommentAuthorDetails name={user.name} email={(user.email == null && transfer != null) ? transfer.receiverMail : user.email} avatar={user.avatar} userId={user.id} newComment={true}/>
          }
        </HoverCard.Dropdown>
      </HoverCard>
    <Flex width="100%" UNSAFE_style={{overflowX:'hidden'}} direction="column" gap="size-100">
    <Textarea
    variant="unstyled"
    autosize
    placeholder={(replyToId == null) ? "Add your thoughts ..." : "Reply ..."}
    value={comment} onChange={(event) => setComment(event.currentTarget.value)}
    onFocus={() => setOnFocus(true)} onBlur={() => setOnFocus(false)}
    classNames={classes}
    maxRows={10}
  />
  {(isSelection && (replyToId == null)) &&
  <>
<Quote selectionContent={selectionContentIntern} />
  </>
}
    </Flex>
    {(replyToId == null && enableSelection) &&
  <HoverCard shadow="md">
    <HoverCard.Target>
        
    <ActionIcon onClick={() => toggleSelection()} radius="lg">
  {(!(isSelection)) &&
  <IconClick width="16px" />
}
{(isSelection) &&
  <IconX width="16px" />
}
</ActionIcon>
    </HoverCard.Target>
    <HoverCard.Dropdown>
      <Text size="sm">
        Highlight an area in the preview
      </Text>
    </HoverCard.Dropdown>
  </HoverCard>
}
    </Flex>
    {(comment != "" || isSelection || onFocus) &&
  <Flex direction="row" justifyContent="right" marginTop="size-100" gap="size-100">
    {(replyToId == null) &&
    <Chip classNames={classes} checked={pinned} color="accent" onChange={() => setPinned((v) => !v)} disabled={isSelection} size="xs" radius="lg" defaultChecked mr="auto">{pinned ? "Pinned to file" : "Pin to file"}</Chip>
    }
  <Button onClick={() => {
   close()
    
    }} variant="subtle" color="dark" radius="lg" size="xs" compact>
  Cancel
</Button>
  <Button onClick={() => post()} compact radius="lg" size="xs">
  Post
</Button>
  </Flex>
}
</>
  );
  return (
    <>
    {(replyToId != null) &&
    [inner]
    }
    {(replyToId == null) &&
    <Paper shadow="xs" radius="md" p="xs" withBorder className='newComment'>
       {[inner]}
    </Paper>
    }
    </>
  )
}

export default NewComment