import React from "react";

import "./style/Spinner.css";
import { ProgressCircle } from "@adobe/react-spectrum";

export function Spinner() {
  return (
    <ProgressCircle
        UNSAFE_className="loader"
        aria-label="Loading…"
        isIndeterminate
      />
  );
}
