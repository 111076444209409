import { Anchor, Avatar, Button, Group, Paper, Space, Text, Timeline } from '@mantine/core'
import React, { useContext, useEffect, useState } from 'react'


import './NewComment.css'
import NewComment from './NewComment'
import { selectThreadById } from './model/threads'
import Comment from './Comment'
import { useDispatch, useSelector } from 'react-redux'

import './Thread.css'
import { hideHighlights, highlightInViewer } from '../PDFViewer/PDFViewer'
import CommentBullet from './CommentBullet'
import CommentAuthor from './CommentAuthor'
import { deleteComment } from './model/comments'
import { transferContext } from '../../DownloadPill/transferContext'
import { fileContext } from '../fileContext'

function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}


function Thread({threadId, setCommentOnFocus, onFocus, setOpenFileKey}) {

  const transfer = useContext(transferContext);

  const files = useContext(fileContext);

  const thread = useSelector((state) => selectThreadById(state, threadId))

  const dispatch = useDispatch()

  const deleteCom = async (comment) => {
      try {
        dispatch(deleteComment(comment))
      } catch (err) {
        console.error('Failed to delete the thread: ', err)
      }
  }

  let content
  content = thread.comments.map((commentId, index) => {
    if((index == 0 || index == (thread.comments.length - 1)) || onFocus) {

      return(<Timeline.Item key={"comment-"+commentId} bullet={<CommentBullet commentId={commentId} />} title={<Group grow>
        <CommentAuthor commentId={commentId} />
        {(index == 0 && thread.isAuthor) &&
        <Anchor size="xs" style={{textAlign: "right"}} onClick={() => {deleteCom(commentId)}}>
        Resolve thread
      </Anchor>
      }
      </Group>}>
      <Comment commentId={commentId} />
      {(index == 0  && thread.comments.length > 2 && !onFocus) &&
      <>
      <Space h="xs" />
       <Anchor size="sm" td="underline" onClick={(e) => {e.stopPropagation(); isOnFocus();}}>
       show {thread.comments.length - 2} hidden comment{((thread.comments.length - 2) > 1) ? "s" : ""}
     </Anchor>
     </>
      }
    </Timeline.Item>)



    }
    
})

async function isOnFocus() {
  const key = files.files.findIndex(file => file.id == thread.file_id)
  setOpenFileKey(key);
  setCommentOnFocus(thread.id);
  if(thread.file_id != files.currentFile.id && thread.file_id != null) {
    await delay(500);
  }
  highlightInViewer(thread.id);
}

  return (
    <>
        <Paper className='thread' onClick={() => {isOnFocus()}} withBorder={onFocus} shadow="xs" radius="md" p="md">
    <Timeline active={0} bulletSize={24} lineWidth={1}>
    {content}
    </Timeline>
    <Space h="xs" />
    <NewComment replyToId={thread.id} />
    </Paper>


    </>
  )
}

export default Thread