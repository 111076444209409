import { useToggle, upperFirst, useDisclosure } from '@mantine/hooks';
import {isEmail, useForm} from '@mantine/form';
import {
  TextInput,
  PasswordInput,
  Text,
  Paper,
  Group,
  Button,
  Checkbox,
  Anchor,
  Title,
  Center,
  Image,
  LoadingOverlay,
  Box,
  rem,
  Space,
} from '@mantine/core';
import ConsentModal from '../ConsentModal/components/ConsentModal';
import {useContext, useEffect, useState} from 'react';
import { UserAvatarUploader } from './UserAvatarUploader';
import {AlertDialog, DialogContainer, Flex, Form, ProgressCircle, TextField} from '@adobe/react-spectrum';
import { IconArrowLeft } from '@tabler/icons-react';

import './AuthPage.css'
import FadeInOut from '../Miscellaneous/FadeInOut';
import {ResetPassword} from "./ResetPassword";
import {userContext} from "../Auth/userContext";

export function AuthPage(props) {
  const queryParameters = new URLSearchParams(window.location.search)
  const redirectUri = decodeURI(queryParameters.get("redirect_to"));
  const [type, toggle] = useToggle(['register', 'login']);
  const [loader, handleLoader] = useDisclosure(true);
  const [siteName, setSiteName] = useState("");

  const [isPasswordResetOpen, setIsPasswordResetOpen] = useState(queryParameters.has("reset_code"));
  const [isSendResetOpen, setIsSendResetOpen] = useState(false);
  const [ sendResetSuccess, setSendResetSuccess ] = useState(false);
  const [wrongResetEmail, setWrongResetEmail] = useState(false);
  const [isAccountDeletionConfirmOpen, setIsAccountDeletionConfirmOpen] = useState(queryParameters.has("accountDeleted"));

  const [name, setName] = useState("");

  const user = useContext(userContext);

  const urlParams = new URLSearchParams(window.location.search)
  const loginForm = useForm({
    initialValues: {
      email: '',
      password: '',
      terms: true,
    },

    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
      password: (val) => (val.length <= 8 ? 'Password should include at least 8 characters' : null),
    },
  });

  const signupForm = useForm({
    initialValues: {
      email: '',
      name: "",
      password: '',
      customAvatar: null,
      marketing: true,
    },

    validate: {
      name: (val) => (val.length > 0 ? null : 'Please share your name'),
      repPassword: (val) => (val == loginForm.values.password ? null : "Passwords don't match")
    },
  });

  const [logo, setLogo] = useState(null);

  function initSiteData(data) {
    setLogo(process.env.REACT_APP_YSNDIT_ENDPOINT + data.logo.substring(1));
    const title = document.getElementById("title");
    title.textContent = "Sign in | " + data.siteName;
    const description = document.getElementById("description");
    description.content = data.description;
    const favicon = document.getElementById("favicon");
    favicon.href = process.env.REACT_APP_YSNDIT_ENDPOINT + data.favicon.substring(1);
    const largerIcon = document.getElementById("larger_icon");
    largerIcon.href = process.env.REACT_APP_YSNDIT_ENDPOINT + data.logo.substring(1);
    setSiteName(data.siteName);
  }

  function goBack(success) {
    window.close();
    window.location.href = "/"
  }

  function sendResetPassword() {

    if(isEmail(loginForm.values.email)) {
      // POST request using fetch inside useEffect React hook

      const url = new URL(window.location.href);
      const search_params = url.searchParams;

      search_params.set('email', loginForm.values.email);
      search_params.set('reset_code', "");

      // change the search property of the main url
      url.search = search_params.toString();

      const new_url = url.toString();




      var formdata = new FormData();
      formdata.append("email", loginForm.values.email);
      formdata.append("reset_link", new_url);

      var myHeaders = new Headers();
      if (process.env.NODE_ENV == 'development') {
        myHeaders.append("CF-Access-Client-Id", process.env.REACT_APP_CF_ID);
        myHeaders.append("CF-Access-Client-Secret", process.env.REACT_APP_CF_SECRET);
      }

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      fetch(process.env.REACT_APP_YSNDIT_ENDPOINT + "user/sendResetPassword", requestOptions)
          .then(response => response.json())
          .then(data => {
            if(data["send"]) {
              setWrongResetEmail(false);
              setIsSendResetOpen(false);
              setSendResetSuccess(true);
            }
            else {
              setWrongResetEmail(true);
            }

          });
    }
    else {
      setWrongResetEmail(true);
    }

  }

  const handleKeyDownLoin = (event) => {
    if (event.key === 'Enter') {
      // Get input value
      login()
    }
  };

  const handleKeyDownSignup = (event) => {
    if (event.key === 'Enter') {
      // Get input value
      login()
    }
  };


  function login() {

    loginForm.validate();
    if(loginForm.isValid()) {
      handleLoader.open();
        // POST request using fetch inside useEffect React hook
    var formdata = new FormData();
    formdata.append("email", loginForm.values.email);
    formdata.append("password", loginForm.values.password);
    
    var myHeaders = new Headers();
    if (process.env.NODE_ENV == 'development') {
      myHeaders.append("CF-Access-Client-Id", process.env.REACT_APP_CF_ID);
      myHeaders.append("CF-Access-Client-Secret", process.env.REACT_APP_CF_SECRET);
    }
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
      credentials: 'include'
    };
    fetch(process.env.REACT_APP_YSNDIT_ENDPOINT + "user/login", requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data["authenticated"]) {
              window.location.href = redirectUri;
            }
            else {
              handleLoader.close();
                loginForm.setFieldError('password', 'The provided password is incorrect');
            }
          
        });

    }

    
  }

  function signup() {
    
    signupForm.validate();
    loginForm.validate();
    if(signupForm.isValid() && loginForm.isValid()) {
      handleLoader.open();
        var formdata = new FormData();
        formdata.append("name", signupForm.values.name);
        formdata.append("email", loginForm.values.email);
        formdata.append("newsletter", signupForm.values.marketing);
        formdata.append("password", loginForm.values.password);
        formdata.append("avatar", signupForm.values.customAvatar);
        
        var myHeaders = new Headers();
        if (process.env.NODE_ENV == 'development') {
          myHeaders.append("CF-Access-Client-Id", process.env.REACT_APP_CF_ID);
          myHeaders.append("CF-Access-Client-Secret", process.env.REACT_APP_CF_SECRET);
        }
        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: formdata,
          redirect: 'follow',
          credentials: 'include'
        };
        fetch(process.env.REACT_APP_YSNDIT_ENDPOINT + "user/signup", requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data["authenticated"]) {
                  window.location.href = redirectUri;
                }
                else {
                  handleLoader.close();
                  loginForm.setFieldError('email', 'An user with this mail already exists');
                }
              
            });
    }
    
    }

    useEffect(() => {
      if(user != null && user.authenticated) {
        window.location.href = "/profile"
      }
      else {
        handleLoader.close()
      }
    }, [user])

  return (
   <>
     <DialogContainer onDismiss={() => setIsPasswordResetOpen(false)}>
       {isPasswordResetOpen &&
           <ResetPassword />
       }
     </DialogContainer>

     <DialogContainer onDismiss={() => setSendResetSuccess(false)}>
       {(sendResetSuccess) &&
           <AlertDialog
               variant="confirmation"
               title="You received an email"
               primaryActionLabel="Ok"
               onPrimaryAction={() => {setSendResetSuccess(false); setIsSendResetOpen(false);}}
           >
             Please click on the link in the email we sent you. There you can set a new password.
           </AlertDialog>
       }
     </DialogContainer>


     <DialogContainer>
       {(isSendResetOpen) &&
           <AlertDialog
               variant="confirmation"
               title="Reset your password"
               primaryActionLabel="Ok"
               onPrimaryAction={() => sendResetPassword()}
               cancelLabel="Cancel"
               onCancel={() => setIsSendResetOpen(false)}
           >
             We will send you a link to your email to reset your password
             <Form>
               <TextField type='email' isRequired necessityIndicator="icon" validationState={(wrongResetEmail) ? 'invalid' : ''} errorMessage="An account with this email does not exist" value={loginForm.values.email} onChange={(input) => loginForm.setFieldValue("email", input)} label="Email" />
             </Form>
           </AlertDialog>
       }
     </DialogContainer>



     <DialogContainer onDismiss={() => setIsAccountDeletionConfirmOpen(false)}>
       {isAccountDeletionConfirmOpen &&
           <AlertDialog
               variant="confirmation"
               title="Account has been deleted"
               primaryActionLabel="Ok">
             Your account and associated transfers have been successfully deleted
           </AlertDialog>
       }
     </DialogContainer>





   <ConsentModal requestedPage="download" reactCallback={null} reactPreflight={initSiteData} />
   <div className="center">
   <FadeInOut show={logo != null}>
   <Image height={80} fit="contain" src={logo} />
   </FadeInOut>
               


      {(type == 'login') &&  
      <>    
      <Title
        align="center"
        sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
      >
        Welcome back!
      </Title>
      <Text color="dimmed" size="sm" align="center" mt={5}>
        Do not have an account yet?{' '}
        <Anchor onClick={() => {toggle(); document.getElementById("title").textContent = "Sign up | " + siteName;}} size="sm" component="button">
          Create account
        </Anchor>
      </Text>
      </>
    }

{(type == 'register') &&  
      <>    
      <Title
        align="center"
        sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
      >
        Happy to meet you{name}! 
      </Title>
      <Text color="dimmed" size="sm" align="center" mt={5}>
        Already have an account?{' '}
        <Anchor onClick={() => {loginForm.clearFieldError('email'); toggle(); document.getElementById("title").textContent = "Sign in | " + siteName;}} size="sm" component="button">
          Sign in
        </Anchor>
      </Text>
      </>
    }

      <Paper withBorder pos="relative" shadow="md" p={30} mt={30} radius="md">
      <LoadingOverlay visible={loader} overlayBlur={2} radius="md" transitionDuration={100} loader={<ProgressCircle aria-label="Loading…" isIndeterminate />} />
        
        
        
        {(type == 'login') &&
        <>
        <TextInput label="Email" {...loginForm.getInputProps('email')} placeholder="jack@mail.com" required />
        <PasswordInput onKeyDown={handleKeyDownLoin} label="Password" {...loginForm.getInputProps('password')} placeholder="Your password" required mt="md" />
        <Group position="apart" mt="lg">
       {1==0 && <Checkbox label="Remember me" />}
          <Anchor component="button" onClick={() => {setIsSendResetOpen(true)}} size="sm">
            Forgot password?
          </Anchor>
        </Group>
        <Button onClick={() => {login()}} fullWidth mt="xl">
          Sign in
        </Button>
        </>
        }

        {(type == 'register') &&
        <>
        <Flex direction="row" alignItems="center" justifyContent="center" gap="size-250">

        <UserAvatarUploader initialAvatar={null} username={signupForm.values.name} setCustomAvatar={(value) => {signupForm.setFieldValue('customAvatar', value)}} width="150px" />
        <TextInput label="Display name" {...signupForm.getInputProps('name')} onBlur={(event) => setName((event.currentTarget.value !== "") ? ", " + event.currentTarget.value : "")}
            placeholder="Jack" required />
        </Flex>
        
        <TextInput label="Email" {...loginForm.getInputProps('email')} placeholder="jack@mail.com" required mt="md" />
        <PasswordInput label="Password" {...loginForm.getInputProps('password')} placeholder="Your password" required mt="md" />
            <PasswordInput onKeyDown={handleKeyDownSignup} label="Repeat password" {...signupForm.getInputProps('repPassword')} placeholder="And once again" required mt="md" />
        <Group position="apart" mt="lg">
       <Checkbox {...signupForm.getInputProps('marketing', { type: 'checkbox' })} label="Keep me informed about product news. You can unsubscribe at any time." />
        </Group>
        <Button onClick={() => {signup()}} fullWidth mt="xl">
          Sign up
        </Button>
        </>
        }

      
     
      
      
      
      </Paper>
      <Space h="xl" />
      <Anchor onClick={() => {goBack(false);}} target="_blank">
      <Center pb={25}>
        <IconArrowLeft size={rem(14)} />
        <Box ml={5}>Back to website</Box>
      </Center>
    </Anchor>
    </div>
    </>
    
  );
}