export const Transfer_STATE = {

  // transfer not found
  NOT_FOUND: {
     showMessage: true,
     variant: "negative",
     message: "A transfer with this url wasn't found. Double check the url and try again"
  },

  // transfer is deleted
  DESTROYED: {
    showMessage: true,
    variant: "negative",
    message: "The transfer is removed. Contact sender to resend it"
 },

  // transfer is still being processed
  PROCESSING: {
    showMessage: true,
    variant: "info",
    message: "Please check back in a few moments. Files are still being uploaded"
  },

  // user has no right to view
  NOT_ALLOWED: {
    showMessage: true,
    variant: "negative",
    message: "You do not have access to the transfer. Probably because you have already downloaded it"
  },

  // user is required to enter password
  NOT_AUTHENTICATED: {
    showMessage: false,
    variant: "",
    message: ""
  },

  // download is showable
  OK: {
    showMessage: false,
    variant: "",
    message: ""
  },
};


export default class Transfer {
    constructor(upload_id, size=0, fileCount=0, dateAdded = 0, adFree = false, allowComments, expiration = 0, message, emailFrom = null, receiverMail, passwordProtected = false, enteredPassword="", authenticated = false, allowed=false, isUploader=false, onlyOnce = false, status = "ready", backgroundUrl = "", logoUrl = "", logoLink = "", siteData=null) {
      this.upload_id = upload_id;
      this.size = parseInt(size);
      this.fileCount = fileCount;
      this.dateAdded = parseInt(dateAdded);
      this.adFree = adFree;
      this.expiration = parseInt(expiration);
      this.message = (!message) ? null : message.replaceAll("&&$$", "\n");
      this.emailFrom = emailFrom;
      this.receiverMail = (receiverMail) ? receiverMail: null;
      this.passwordProtected = passwordProtected;
      this.enteredPassword = enteredPassword;
      this.authenticated = authenticated;
      this.allowed = allowed;
      this.onlyOnce = (onlyOnce == "yes") ? true : false;
      this.status = status;
      this.backgroundUrl = backgroundUrl;
      this.logoUrl = logoUrl;
      this.logoLink = logoLink;
      this.isUploader = isUploader;
      this.siteData = siteData;
      if(allowComments == "yes") {
        this.allowComments = true;
      } else {
        this.allowComments = false;
      }
      if(this.status == null) {
        this.Transfer_STATE = Transfer_STATE.NOT_FOUND;
      }
      else if(this.status == "destroyed") {
        this.Transfer_STATE = Transfer_STATE.DESTROYED;
      }
      else if(this.status == "processing") {
        this.Transfer_STATE = Transfer_STATE.PROCESSING;
      }
      else if(this.status == "inactive" || !this.allowed) {
        this.Transfer_STATE = Transfer_STATE.NOT_ALLOWED;
      }
      else if(!this.authenticated) {
        this.Transfer_STATE = Transfer_STATE.NOT_AUTHENTICATED;
      }
      else {
        this.Transfer_STATE = Transfer_STATE.OK;
      }
  }
}