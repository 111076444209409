import { useState, useEffect, useRef } from 'react';
import { FileList } from './FileList';
import { View } from '@adobe/react-spectrum';

import { MainRow } from './MainRow';
import Transfer, { Transfer_STATE } from './Transfer';
import File from './File';
import AnimateHeight from 'react-animate-height';
import { LoadingRow } from './LoadingRow';
import FadeInOut from '../Miscellaneous/FadeInOut';
import PasswordDialog from './PasswordDialog';
import DeleteDialog from './DeleteDialog';
import posthog from 'posthog-js';
import ConsentModal from '../ConsentModal/components/ConsentModal';
import SiteData from './SiteData';
import { ConsentHandler } from '../ConsentModal/components/Consent';


export function DownloadPill({setFile, setUpperFiles, setUpperTransfer, setUpperSiteData, fullscreen, openFileKey}) {

  const params = new URLSearchParams(document.location.search);
  
  const [openedFile, setOpenedFile] = useState(null);
  const [transfer, setTransfer] = useState(null);
  const [files, setFiles] = useState([new File()]);

  const [enteredPassword, setEnteredPassword] = useState("");
  const [isDelete, setDelete] = useState(0);
  const [timesFetchedData, setTimesFetchedData] = useState(0);

  const[isDeleteModalOpen, setIsDeleteModalOpen] = useState(params.get("delete") == 1);

  const paths = window.location.pathname.split("/");

  /**
   * For FileList
   */
  const ref = useRef();
  const [initialFileListHeight, setInitialFileListHeight] = useState();
  const [isFileListOpen, setFileListOpenState] = useState(false);
  const [fileListHeight, setFileListHeight] = useState(0);
  const [selectFiles, setSelectFiles] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);

  const [consent, setConsent] = useState(null);

  useEffect(() => {

    if(consent != null) {
      // POST request using fetch inside useEffect React hook
    var formdata = new FormData();
    formdata.append("download_id", paths[1]);
    formdata.append("private_id", (paths.length > 2) ? paths[2] : '');
    formdata.append("password", enteredPassword);
    formdata.append("delete", isDelete);
    
    var myHeaders = new Headers();
    if (process.env.NODE_ENV == 'development') {
      myHeaders.append("CF-Access-Client-Id", process.env.REACT_APP_CF_ID);
      myHeaders.append("CF-Access-Client-Secret", process.env.REACT_APP_CF_SECRET);
    }
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_YSNDIT_ENDPOINT + "api/client/download", requestOptions)
        .then(response => response.json())
        .then(data => {
          const tempSiteData = new SiteData(data.downloadBackground, data.logo, data.termsUrl, data.imprintUrl, data.privacyUrl, data.siteName, data.description, data.favicon, data.googleAdId, data.slot_MainRow, data.alternateAdImage, data.alternateAdLink, data.helpUrl, consent);
          const transfer = new Transfer(data.upload_id, data.size, data.count, data.time, data.adFree, data.allow_comments, data.time_expire, data.message, data.email_from, data.receiver_mail, data.password, enteredPassword, data.authenticated, data.allowed, data.isUploader, data.destruct, data.status, data.backurl, data.logourl, data.redurl, tempSiteData);
          if(!transfer.adFree) {
            ConsentHandler.appendScript("https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4451680474049677", {crossorigin: "anonymous", async: true}, "head");
          }
          setUpperTransfer(transfer);
          setTransfer(transfer);
          initSiteData(data); // update logos if they are old in two days consent cache
          if(transfer.Transfer_STATE == Transfer_STATE.OK) {
            let tempFiles = [];
            data.files.sort((a, b) => a.size - b.size);
          data.files.forEach((file, key) => tempFiles.push(new File(file.key, file.id, file.name, file.source, file.extension, file.size, key, file.preview_url)));
          setFiles(tempFiles);
          setUpperFiles(tempFiles);
            if(tempFiles.length > 1) {
              setFileListOpenState(true);
              setFileListHeight("auto");
            }
            else if(tempFiles.length == 1) {
              handleFileOpening(tempFiles[0]);
            }
          }
          else {
            setFiles([]);
            setUpperFiles([]);
            setFileListOpenState(false);
            setFileListHeight(0);
            setFile(null);
          }
          setTimesFetchedData(timesFetchedData + 1);
          
        });


        if (!window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost')) {
          posthog.init('phc_6Ft66C6ijHSio8nVqv6PdsiBVLekIbICerNu9umtrGE',{api_host:'https://app.posthog.com', opt_in_site_apps: true, disable_persistence: !(consent["consentOptions"]["analyticsCookie"])});
        }
    }
    

// empty dependency array means this effect will only run once (like componentDidMount in classes)
}, [enteredPassword, isDelete, consent]);


function initSiteData(data) {
  const title = document.getElementById("title");
  title.textContent = "Transfer " + paths[1] + " | " + data.siteName;
  const description = document.getElementById("description");
  description.content = data.description;
  const favicon = document.getElementById("favicon");
  favicon.href = process.env.REACT_APP_YSNDIT_ENDPOINT + data.favicon.substring(1);
  const largerIcon = document.getElementById("larger_icon");
  largerIcon.href = process.env.REACT_APP_YSNDIT_ENDPOINT + data.logo.substring(1);
}


function consentGiven(consent) {
  setConsent(consent);
  ConsentHandler.appendScript("https://ysendit.statuspage.io/embed/script.js", {}, "body");
}

                        const createScriptElement = (scriptSrc, attributes) => {
                          const el = document.createElement('script')
                          el.src = scriptSrc
                          for (let [key, value] of Object.entries(attributes)) {
                              el.setAttribute(key, value)
                          }
                          return el
                      }

                      // Function that appends script to the <target>. Could also be adjusted to prepend
                      // it before the closing </body> if you want to.
                      const appendScriptElement = (el, target) => {
                          document.getElementsByTagName(target)[0].appendChild(el)
                      }

useEffect(() => {
  if(consent != null && transfer != null) {
    const timer = setTimeout(() => {
      if (typeof window !== 'undefined')
      window.adsbygoogle=window.adsbygoogle||[]
      window.adsbygoogle.pauseAdRequests=(transfer.adFree) ? 1 : 0;
      if(consent["consentOptions"]["adsPersonalized"]) {
        window.adsbygoogle.requestNonPersonalizedAds=0;
      }
      else {
        window.adsbygoogle.requestNonPersonalizedAds=1;
      }
    }, 200);

    
    if(!transfer.adFree) {
      // ConsentHandler.appendScript("https://alwingulla.com/88/tag.min.js", {async: true, "data-zone": "10092", "data-cfasync": false}, "head");
    /*
      ConsentHandler.appendScript("https://cdn.prplads.com/video-agent.js?publisherId=8caeab15a5811c1847ea1bc0fb6ffdab:e6ae9d46b66683397665e4055c845855fd0b882195582ac9bee0055808ab68bc0eafd874d84b776bc4cae63de05702ca95c3d73da6770b672afda585b6c89b7b", {async: true}, "head");
    ConsentHandler.appendScript("https://cdn.prplads.com/load.js?publisherId=8caeab15a5811c1847ea1bc0fb6ffdab:e6ae9d46b66683397665e4055c845855fd0b882195582ac9bee0055808ab68bc0eafd874d84b776bc4cae63de05702ca95c3d73da6770b672afda585b6c89b7b", {id: "purpleads-client"}, "head");
    */
  }
   

    return () => clearTimeout(timer);
  }
}, [consent, transfer]);

  
  function handleFileOpening(file, hideFileList) {
    // close filelist for to not have it freezen while loading file
    if(hideFileList) {
      setFileListOpen(false);
      }
    setOpenedFile(file);
    if(!selectFiles) {
      setSelectedFiles([file]);
    }

    setFile(file); // lastly load file in preview (can freeze other)
    posthog.capture('preview file', {uploadID: (transfer != null) ? transfer.upload_id : "", fileType: (file != null) ? file.type : ""});
  }

  function showFiles() {
    setOpenedFile(null);
  }

  function setFileListOpen(open) {
    if(open != isFileListOpen) {
      setFileListHeight(ref.current.clientHeight); // here to fix some design bugs
    setFileListOpenState(open);
    if(open) {
      setFileListHeight(initialFileListHeight);
    }
    else {
      setInitialFileListHeight(ref.current.clientHeight);
      setFileListHeight(0);
    }
    }
  }

  return (
    <>
    <ConsentModal requestedPage="download" reactCallback={consentGiven} reactPreflight={initSiteData} />
    <PasswordDialog transfer={transfer} setEnteredPassword={setEnteredPassword} timesFetchedData={timesFetchedData} />
    <DeleteDialog transfer={transfer} isDeleteModalOpen={isDeleteModalOpen} setIsDeleteModalOpen={setIsDeleteModalOpen} setDelete={setDelete} />

    <View borderTopStartRadius={{ base: 'none', S: 'medium' }} borderBottomStartRadius={{ base: 'none', S: 'medium' }} borderBottomEndRadius={{ base: 'none', S: 'medium' }} UNSAFE_style={{opacity: fullscreen ? 0 : 1, visibility: fullscreen ? "hidden":"visible", transition: "all 0.3s ease-in", borderTopRightRadius: "max(0px, min(8px, calc((100vw - 4px - 100%) * 9999)))", overflow:"hidden"}} borderColor={{ base: 'transparent', S: 'gray-300' }} borderTopColor="gray-300" borderWidth="thin" backgroundColor="gray-50" left={{ base: 'size-0', S: 'size-200' }} bottom={{ base: 'size-0', S: 'size-200' }} position="fixed" zIndex={5} maxWidth="520px">
    <FadeInOut show={transfer != null && consent != null} duration={200}>
      <AnimateHeight
        id="example-panel"
        duration={600}
        easing="ease"
        animateOpacity={true}
        height={fileListHeight} // see props documentation below
      >
        <div ref={ref}>
<FileList selection={selectFiles} files={files} filePreviewHandler={handleFileOpening} fileSelectionHandler={setSelectedFiles} transfer={transfer} openFileKey={openFileKey}/>
</div>
</AnimateHeight>
</FadeInOut>
<View position="relative">
<FadeInOut show={transfer != null && consent != null && !fullscreen} duration={200}>
    <MainRow setSelectFiles={setSelectFiles} file={openedFile} transfer={transfer} files={files} selectedFiles={selectedFiles} showFiles={showFiles} isFileListOpen={isFileListOpen} setFileListOpen={setFileListOpen} privateId={(paths.length > 2) ? paths[2] : ''} setIsDeleteModalOpen={setIsDeleteModalOpen} isFullscreen={fullscreen} />
</FadeInOut>


{(transfer == null) &&
    <LoadingRow/>
}
    </View>
    </View>
    </>
  );
}
